import { Action } from "../../models/action";
import { FormState } from "../../models/form_states";
import * as types from "../types";

export interface OfferState {
  offers: any[];
  state: FormState;
  error?: string;
  lastDoc?: any;
  totalDocs: number; 
  add: {
    error?: string;
    state: FormState;
  };
  edit: {
    state: FormState;
    selectedOferta?: any;
    error?: string;
  };
  delete: {
    state: FormState;
    error?: string;
  };
  selected?: any;
}

const initialState: OfferState = {
  offers: [],
  state: FormState.Initial,
  totalDocs: 0,
  add: {
    state: FormState.Initial
  },
  delete: {
    state:FormState.Initial
  },
  edit: { selectedOferta: [], state: FormState.Initial },
  selected:[]
};

export default function reducer(
  state = initialState,
  action: Action
): OfferState {
  switch (action.type) {
    case types.OFFER_GET_SUCCESS:
      return {
        ...state,
        offers: action.payload.offers,
        state: FormState.Success,
        lastDoc: action.payload.lastDoc,
        totalDocs: action.payload.totalDocs,
      };
    case types.OFFER_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.OFFER_GET_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
      case types.OFFER_ADD_INITIAL:
      return {
        ...state,
        add: initialState.add,
      };
    case types.OFFER_ADD_SUBMITTING:
      return {
        ...state,
        add: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.OFFER_ADD_SUCCESS:
      return {
        ...state,
        add: {
          state: FormState.Success,
        },
      };
    case types.OFFER_ADD_FAILURE:
      return {
        ...state,
        add: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
      case types.OFFER_GET_ONE_SUBMMITING:
      return {
        ...state,
      };
    case types.OFFER_GET_ONE_SUCCESS:
      return {
        ...state,
        
        selected: action.payload,
      };
      case types.OFFER_GET_ONE_INITIAL:
      return {
        ...state,
        selected: undefined,
      };
    case types.OFFER_GET_ONE_FAILURE:
      return {
        ...state,
      };
      case types.OFFER_EDIT_INITIAL:
      return {
        ...state,
        edit: {
          ...initialState.edit,
        },
      };
    case types.OFFER_EDIT_SUBMITTING:
      return {
        ...state,
        edit: {
          ...state.edit,
          state: FormState.Submitting,
        },
      };

    case types.OFFER_EDIT_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          state: FormState.Success,
        },
      };
    case types.OFFER_EDIT_FAILURE:
      return {
        ...state,
        edit: {
          ...state.edit,
          state: FormState.Failure,
          error: action.payload,
        },
      };
      case types.OFFER_DELETE_SUBMITTING:
      return {
        ...state,
        delete: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.OFFER_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          state: FormState.Success,
        },
        offers: state.offers.filter((x) => x.id !== action.payload.id),
      };
    case types.OFFER_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
