import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Button";
import { FormState } from "../../models/form_state";
import { getAdvertising } from "../../redux/actions/advertising.action";
import { getMoreOffers, getMyOffers } from "../../redux/actions/offer.action";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { OfferState } from "../../redux/reducers/offer.reducer";
import { RootState } from "../../redux/reducers/root.reducer";
import { OffersCard } from "./components/OffersCard";

export const OfferList = () => {
  const dispatch = useDispatch();

  const {
    state: offersState,
    totalDocs,
    offers,
    delete: { state: deleteState },
  } = useSelector<RootState, OfferState>((state) => state.offerReducer);

  useEffect(() => {
    dispatch(getMyOffers());
    // eslint-disable-next-line
  }, [deleteState]);
  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);

  const moreOffers = (total: any) => {
    dispatch(getMoreOffers(total));
  };

  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Mis Ofertas" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Mis Ofertas" && x.Posicion === "inferior"
  );
  return (
    <main className="py-10 px-8 lg:container lg:mx-auto">
      <Helmet title="Ofertas Realizadas" />
      <h1 className=" text-3xl font-bold text-deepBlue ">
        {`Ofertas Realizadas (${totalDocs})`}
      </h1>
      {AdvertisingSup.length === 1 && (
        <a href={AdvertisingSup[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingSup[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingSup[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}
        </a>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 xl:grid-cols-4 2xl:grid-cols-5 mt-8">
        {offersState === FormState.Submitting ||
        offersState === FormState.Initial ||
        deleteState === FormState.Submitting ? (
          new Array(5).fill(0).map((value, i) => {
            return (
              <div
                className="h-96 bg-white shadow-md rounded-xl overflow-hidden"
                key={`skeleton-div-${i}`}
              >
                <div className="w-full h-1/2 bg-gray-200 animate-pulse"></div>

                <div className="w-full h-1/2 flex flex-col ">
                  <div className="w-3/4 h-4 pulse rounded-full bg-gray-200 mt-2 mx-4"></div>
                  <div className="mx-4 mt-10">
                    <div className="w-3/4 h-3 pulse rounded-full bg-gray-200"></div>
                    <div className="w-2/4 h-2 pulse rounded-full bg-gray-200 mt-2"></div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <React.Fragment>
            {offers.map((offer, index) => (
              <OffersCard key={`offer-${index}`} offer={offer} />
            ))}
          </React.Fragment>
        )}
      </div>
      <div className="mt-10 flex items-center justify-center ">
        <Button
          type="submit"
          text="Cargar más ofertas"
          color="secondary"
          className="mt-10  flex items-center justify-center "
          onClick={() => moreOffers(totalDocs)}
        />
      </div>
      {AdvertisingBot.length === 1 && (
        <a href={AdvertisingBot[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingBot[0].Imagen.URL}
              className="mx-auto rounded-md mt-5 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingBot[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}
        </a>
      )}
    </main>
  );
};
