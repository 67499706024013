import { format } from "date-fns";
import React, { useEffect } from "react";
import {
  Calendar as CalendarIcon,
  MapPin,
  Star,
  Tag as TagIcon
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { MAPS_API_KEY } from "../configs/constants";
import { PlaintiffOffer } from "../models/PlaintiffOffer";
import { PlaintiffRequest } from "../models/PlaintiffRequest";
import { ImagesDisplay } from "./ImagesDisplay";
import { RootState } from "../redux/reducers/root.reducer";
import { CalificationState } from "../redux/reducers/calification.reducer";
import { calificationId } from "../redux/actions/calification.action";

interface Props {
  request: PlaintiffRequest;
}
export const RequestDetailsSection: React.FC<Props> = ({ request }) => {
  const dispatch = useDispatch();

  const { calificationIdUser } = useSelector<
  RootState,
  CalificationState
>((state) => state.calificationReducer);


console.log(calificationIdUser)
  const showMapPin = (request: any) => {
    if(request.OfertaAceptada){
      return (`https://maps.googleapis.com/maps/api/staticmap?language=es&markers=${
        request.Ubicacion.lat + "," + request.Ubicacion.lng
      }&size=500x500&key=${MAPS_API_KEY}`)
    }else {
      return (`https://maps.googleapis.com/maps/api/staticmap?language=es&center=${
        request.Ubicacion.lat + "," + request.Ubicacion.lng
      }&zoom=15&size=500x500&key=${MAPS_API_KEY}`)
    }
  }

  useEffect(() => {
    dispatch(calificationId(request.Usuario.Id));
  }, [])
  
  

  return (
    <div>
      <div>
        <h1 className="text-secondary font-bold text-3xl mb-2">
          {request.Titulo}
        </h1>
        <h2 className=" font-bold text-lg text-gray-400">
          <span className="text-primary text-xl text-bold">{`$${request.Precio.toLocaleString(
            "es"
          )} `}</span>
          {`${request.Unidad.Cantidad} ${request.Unidad.Tipo}`}
        </h2>
        <h2>
          <span className="text-deepBlue text-lg flex items-center font-bold">
            <Star size={18} strokeWidth={2.5} className="text-deepBlue mr-2" />

            {/* {`(${request.Usuario.Calificacion})`} */}
            ({calificationIdUser ? calificationIdUser : 5})
          </span>
        </h2>
        <h3 className="text-base text-gray-400 flex flex-col mb-5">
          <span className="flex items-center mr-2">
            <MapPin
              size={13}
              className="text-deepBlue mr-2"
              strokeWidth={2.5}
            />
            {request.Distancia
              ? request.Distancia >= 1000
                ? `${(request.Distancia / 1000).toFixed(1)} km`
                : `${request.Distancia} m`
              : ""}
            · {request.Region} · {request.Comuna}
          </span>
          <span className="font-bold flex items-center">
            <TagIcon
              size={13}
              className="text-deepBlue mr-2"
              strokeWidth={2.5}
            />
            {request.Categoria}
          </span>
        </h3>

        <ImagesDisplay images={request.Imagenes} />

        <div>
          <h4 className="text-lg font-bold text-primary mb-3">Ubicación:</h4>
          <p className="flex items-center mb-3">
            <MapPin
              size={21}
              className="text-deepBlue mr-2"
              strokeWidth={2.5}
            />
            <span>{`${request.Comuna}, ${request.Region}`}</span>
          </p>
          <img
            src={showMapPin(request)}
            className="h-52 w-full object-cover rounded-xl"
            alt="google-map"
          />
        </div>

        <div className="mt-5">
          <h4 className="text-lg font-bold text-primary mb-3">
            Fecha inicio y término:
          </h4>
          <div className="flex text-base text-gray-400 font-semibold">
            <CalendarIcon
              size={21}
              className="text-deepBlue mr-2"
              strokeWidth={2.5}
            />
            <p>
              <span className="text-primary">Fecha Inicio: </span>
              {format(new Date(request.FechaInicio), "dd/MM/yyyy")}
            </p>
          </div>
          <div className="flex text-base text-gray-400 font-semibold mt-1">
            <CalendarIcon
              size={21}
              className="text-deepBlue mr-2"
              strokeWidth={2.5}
            />
            <p>
              <span className="text-primary">Fecha Término: </span>
              {format(new Date(request.FechaTermino), "dd/MM/yyyy")}
            </p>
          </div>
        </div>

        <div className="mt-5">
          <h4 className="text-lg font-bold text-primary mb-2">Descripción: </h4>
          <p className="text-base font-medium text-gray-400">
            {request.Descripcion}
          </p>
        </div>
      </div>
    </div>
  );
};
