import React from 'react'
import Logo from "../assets/img/demandapp.svg"
import Loading from "../assets/img/loading.svg"

export const LoadingPage = () => {
  return (
    <div>
      <img src={Logo} alt="" className="object-contain h-48 w-full px-6" />
      <img src={Loading} alt="" className="object-contain h-48 w-full md:ml-9 xl:ml-16"/>
    </div>
  )
}
