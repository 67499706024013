import * as React from "react"

function KeyOutlinedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" {...props}>
      <path
        d="M43.16 28.77c5.02-3.29 6.44-10.05 3.15-15.08s-10.05-6.44-15.08-3.15c-4.62 3.02-6.18 8.98-3.86 13.82L4.36 39.44l1.58 2.41 1.55-1.02 1.61 2.46 2.41-1.58-1.61-2.46 1.89-1.24 2.11 3.22 2.41-1.58-2.11-3.22 14.75-9.66c3.51 4.08 9.59 5.03 14.21 2zm-12.67-4.72c-2.42-3.69-1.38-8.67 2.31-11.09s8.67-1.38 11.09 2.31 1.38 8.67-2.31 11.09c-3.7 2.42-8.67 1.38-11.09-2.31z"
        fill="#0079c8"
      />
    </svg>
  )
}

export default KeyOutlinedIcon
