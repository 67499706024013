import clsx from "clsx";
import React from "react";

export interface MenuItemProps<T> {
  value: T;
  className?: string;
  label: string;
}

export function MenuItem<T>({
  value,
  className,
  label,
  ...others
}: MenuItemProps<T>) {
  return (
    <li
      className={clsx(
        "px-4 py-2 flex items-center hover:bg-gray-100 cursor-pointer",
        className
      )}
      {...others}
    >
      <span>{label}</span>
    </li>
  );
}
