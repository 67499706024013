import { format, isAfter, isBefore } from "date-fns";
import React from "react";
import { Star, FileText } from "react-feather";
import { FormState } from "../../models/form_states";
import { Docs, PlaintiffOffer } from "../../models/PlaintiffOffer";
import { getFileNameByFirebaseURL } from "../../utils/utils";
import { Button } from "../Button";
import { FileSpace } from "../FileSpace";
import { IconButton } from "../IconButton";

interface Props {
  offer: PlaintiffOffer;
  deleteOfferState: FormState;
  handleReject: (offer: PlaintiffOffer) => void;
  handleRate: (offer: PlaintiffOffer) => void;
}

export const AcceptOfferCard: React.FC<Props> = ({
  offer,
  handleReject,
  deleteOfferState,
  handleRate,
}) => {

  return (
    <>
      <div className="relative overflow-visible">
        <div className="bg-greenOffer rounded-xl p-5 m-2 relative">
          <div>
            <span className="text-deepBlue text-xl font-bold">
              {offer?.Titulo}
            </span>
          </div>
          <div className="text-gray-400 text-base font-semibold">
            <p className="flex flex-row space-x-2">
              <span className="text-primary text-base">
                {`${offer?.Usuario?.Nombre} ${offer?.Usuario?.Apellido}`}
              </span>
              <span className="text-deepBlue text-lg flex flex-row">
                <Star />
                {`(${offer.Usuario.Calificacion})`}
              </span>
              <span>{` · ${offer?.Descripcion}`}</span>
              <span>{` · ${offer?.Usuario?.Comuna}, ${offer?.Usuario?.Region}`}</span>
            </p>
            <p>
              <span>{`Fecha Inicio: ${format(
                new Date(offer?.FechaInicio),
                "dd/MM/yyyy"
              )}`}</span>
              <span>{` · Fecha Fin: ${format(
                new Date(offer?.FechaTermino),
                "dd/MM/yyyy"
              )}`}</span>
            </p>
            <p>
              <span>{`Cantidad: ${offer?.Cantidad?.Cantidad} ${offer?.Cantidad?.Tipo}`}</span>
            </p>
          </div>
          <div>
            <h1 className="text-lg text-blue-800 font-bold">{`Precio: $${offer?.Precio?.toLocaleString(
              "es"
            )}`}</h1>
          </div>
        </div>
      </div>
      <div className="bg-grayContainer rounded-xl p-5 m-2 flex flex-col font-semibold relative">
        <span className="text-lg text-blue-800 font-bold mb-2">
          Datos de Contacto:
        </span>
        <span className="textm-sm text-secondary-light">
          Email: <span className="text-gray-400">{offer.Usuario.Email}</span>
        </span>
        <span className="textm-sm text-secondary-light">
          Teléfono:{" "}
          <span className="text-gray-400">{offer.Usuario.Telefono}</span>
        </span>
      </div>
      {offer.Usuario.Archivos && offer.Usuario.Archivos.length > 0 && (
        <div className="bg-grayContainer rounded-xl p-10 m-2 flex flex-row relative">
          <span className="text-lg text-blue-800 font-bold ">
            Certificaciones:
          </span>
          <div className="flex content-start mt-9 w-full">
            {offer.Usuario.Archivos.map((file: any) => (
              <a href={file.URL} target="_blank" className="flex flex-col m-2 justify-center  w-1/4">
                <IconButton className="">
                  <FileText className="text-deepBlue mt-1" size={50} />
                </IconButton>
                <span>{getFileNameByFirebaseURL(file.URL)}</span>
              </a>
            ))}
          </div>
        </div>
      )}
      <div className="bg-grayContainer rounded-xl p-10 m-2 flex-col">
        <p className="text-sm text-gray-500 font-bold">{offer?.Mensaje}</p>
        <div className="mt-5 justify-center flex">
          {/*  <Button
            text="Confirmar Oferta"
            className="bg-primary-light "
            paddingClassname="py-2 px-7 "
          /> */}
          {isBefore(new Date(), new Date(offer?.FechaTermino)) && (
            <Button
              text="Rechazar Oferta"
              className="bg-redButton ml-5"
              paddingClassname="py-2 px-7"
              onClick={() => handleReject(offer)}
              isLoading={deleteOfferState === FormState.Submitting}
            />
          )}
          {isAfter(new Date(), new Date(offer?.FechaTermino)) &&
            offer?.Calificado === false && (
              <Button
                text="Calificar usuario"
                className="bg-primary"
                onClick={() => handleRate(offer)}
              />
            )}
        </div>
      </div>
    </>
  );
};
