import * as React from "react"

function UserIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" {...props}>
      <circle cx={26} cy={26} r={20.7} fill="#007ac9" />
      <path
        d="M35.76 29.06a13.72 13.72 0 00-5.24-3.29 7.972 7.972 0 003.46-6.57c0-4.4-3.58-7.98-7.98-7.98s-7.98 3.58-7.98 7.98c0 2.72 1.37 5.13 3.46 6.57a13.72 13.72 0 00-5.24 3.29 13.697 13.697 0 00-4.04 9.76h2.16c0-6.42 5.22-11.64 11.64-11.64s11.64 5.22 11.64 11.64h2.16c0-3.69-1.44-7.15-4.04-9.76zM26 25.02c-3.21 0-5.82-2.61-5.82-5.82s2.61-5.82 5.82-5.82 5.82 2.61 5.82 5.82-2.61 5.82-5.82 5.82z"
        fill="#fff"
      />
    </svg>
  )
}

export default UserIcon
