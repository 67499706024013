import React from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { OffererHeader } from "../components/OffererHeader";

interface Props {}

export const OffererLayout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <OffererHeader />
      <Header />
      <div className="h-20"/>
      {children}
      <Footer />
    </div>
  );
};
