import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import CrossIcon from "../assets/icons/CrossIcon";
import { FormState } from "../models/form_state";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { IconButton } from "./IconButton";
import { LoginForm } from "./LoginForm";
import { OffererMenu } from "./OffererMenu";
import { PlaintiffMenu } from "./PlaintiffMenu";
interface Props {
  isVisible: boolean;
  onClose: VoidFunction;
  title?: string;
}

export const Menu: React.FC<Props> = ({ onClose, isVisible }) => {
  const { user, state } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );

  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref?.current?.contains(event.target)) {
      setTimeout(() => {
        onClose();
      }, 50);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AnimatePresence
      initial={false}
      exitBeforeEnter={true}
      onExitComplete={() => null}
    >
      {isVisible && (
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="bg-primary pt-5 pb-10 px-6 flex flex-col items-center z-30 absolute right-0 top-20"
          style={{ width: 400, marginLeft: -50, maxWidth: "100vw" }}
        >
          <div className="flex flex-row items-center justify-center w-full mb-5">
            <h4 className="text-lg font-bold text-white text-center">
              {state !== FormState.Success ? (
                "Ingreso de Usuario"
              ) : (
                <React.Fragment>
                  Bienvenido: <br />
                  <span className="font-semibold">{` ${
                    user?.Nombre === undefined ? "" : user.Nombre
                  } ${
                    user?.Apellido === undefined ? "" : user.Apellido
                  }`}</span>
                </React.Fragment>
              )}
            </h4>
            <IconButton onClick={onClose} className="absolute right-6">
              <CrossIcon className="w-4 h-4" />
            </IconButton>
          </div>
          {state === FormState.Success && user?.TipoUsuario === "Demandante" ? (
            <PlaintiffMenu onClose={onClose} />
          ) : user?.TipoUsuario === "Oferente" ? (
            <OffererMenu onClose={onClose} />
          ) : (
            <LoginForm />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
