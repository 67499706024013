import { Action } from "../../models/action";
import { AdvertisingModel } from "../../models/AdvertisingModel";
import { Categories } from "../../models/Categories";
import { FormState } from "../../models/form_states";
import * as types from "../types";

export interface AdvertisingState {
  advertisings: AdvertisingModel[];
  state: FormState;
  error?: string;
}

const initialState = {
  advertisings: [],
  state: FormState.Initial,
} as AdvertisingState;

export default function reducer(
  state = initialState,
  action: Action
): AdvertisingState {
  switch (action.type) {
    case types.ADVERTISING_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        advertisings: action.payload,
      };
    case types.ADVERTISING_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.ADVERTISING_GET_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    default:
      return state;
  }
}
