import { wrap } from "@popmotion/popcorn";
import { AnimatePresence, motion } from "framer-motion";
import React, { FC, useState } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Docs } from "../models/PlaintiffRequest";

interface Props {
  images?: Docs[];
}

export const ImagesDisplay: FC<Props> = ({ images }) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  if (!images) {
    return (
      <div className="flex flex-col w-full h-80 rounded-xl mb-5 bg-gray-50">
        <img
          src="/assets/img/sin_imagen.jpg"
          className="w-full h-full object-contain overflow-hidden"
        />
      </div>
    );
  }

  const imageIndex = wrap(0, images?.length, page);

  return (
    <div className="flex flex-col w-full h-96 mb-5">
      <div className="h-5/6 bg-gray-50 rounded-xl overflow-hidden flex items-center justify-center mb-4 relative">
        <AnimatePresence>
          <motion.img
            key={page}
            src={images[imageIndex].URL}
            custom={direction}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              x: {
                type: "spring",
                stiffness: 300,
                damping: 300,
                duration: 400,
              },
              opacity: { duration: 1 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(event, info) => {
              if (info.offset.x > 200) {
                paginate(+1);
              }
              if (info.offset.x < -200) {
                paginate(-1);
              }
            }}
            alt="primera-imagen"
            className="w-full h-full object-contain absolute"
          />
        </AnimatePresence>
        <motion.button
          whileHover={{ scale: 1.2, transition: { duration: 0.5 } }}
          whileTap={{ scale: 0.9 }}
          className="absolute left-2 bg-white shadow-md rounded-full flex items-center justify-center p-1"
          onClick={() => paginate(-1)}
        >
          <ArrowLeft />
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.2, transition: { duration: 0.5 } }}
          whileTap={{ scale: 0.9 }}
          onClick={() => paginate(+1)}
          className="absolute right-2 bg-white shadow-md rounded-full flex items-center justify-center p-1"
        >
          <ArrowRight />
        </motion.button>
      </div>
      <div className="h-1/6 flex justify-between items-center">
        {images.map((image, index) => (
          <motion.img
            className=" h-full w-1/4 bg-gray-50 rounded-xl overflow-hidden mr-2 object-contain cursor-pointer"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
              borderWidth: 1,
              borderColor: "rgba(249, 250, 251,1)",
            }}
            whileTap={{ scale: 0.9 }}
            src={image.URL}
            key={`image-display-${index}`}
            onClick={() => setPage([index, 0])}
          />
        ))}
      </div>
    </div>
  );
};
