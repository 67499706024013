import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  Bell as BellIcon,
  Search as SearchIcon,
  Edit2 as EditIcon,
  Trash2 as TrashIcon,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { IconButton } from "../../../components/IconButton";
import { Modal } from "../../../components/Modal";
import { FormState } from "../../../models/form_states";
import {
  deleteAlert,
  getMyAlerts,
  setSelectedAlert,
} from "../../../redux/actions/alert.action";
import { AlertState } from "../../../redux/reducers/alert.reducer";
import { RootState } from "../../../redux/reducers/root.reducer";

interface Props {
  alert: any;
}

export const AlertCard: React.FC<Props> = ({ alert }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEdit = () => {
    dispatch(setSelectedAlert(alert));
    navigate("/oferente/EditarAlerta");
  };
  const handleDelete = () => {
    dispatch(deleteAlert(alert));
    setModal(false);
  };

  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  const handleOnClose = () => {
    setModal(false);
  };

  const {
    delete: { state: deleteState },
  } = useSelector<RootState, AlertState>((state) => state.alertReducer);

  useEffect(() => {
    if (deleteState === FormState.Success) {
      dispatch(getMyAlerts());
    }
  }, [deleteState]);

  return (
    <div >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={
          " w-72 bg-white shadow-md rounded-xl overflow-hidden hover:shadow-xl"
        }
        onMouseEnter={() => {}}
        onMouseLeave={() => {}}
      >
        {/* Campana */}
        <div className="w-full h-16 relative">
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-primary-50">
            <BellIcon
              size={35}
              className="text-primary mx-auto mt-4"
              strokeWidth={2.5}
            />
          </div>
        </div>

        {/* Tarjetas */}
        <div className="w-full h-1/2 flex flex-col justify-between bg-white mb-4">
          <h6 className="text-lg text-secondary font-bold mt-2 mx-4 my-2 ">
            {alert.Titulo}
          </h6>

          <h3 className="text-base text-primary-light font-bold ml-4 pb-2">
            Categoría: {alert.Categorias.map((x: any) => (
              <p className="text-gray-400 ">- {x}. </p>
            ))}
          </h3>

          <h3 className="text-base text-primary-light font-bold ml-4 pb-2">
            Región: <span className="text-gray-400">{alert.Region}</span>
          </h3>
          <h3 className="text-base text-primary-light font-bold ml-4 pb-2">
            Comuna: <span className="text-gray-400">{alert.Comuna}</span>
          </h3>
        </div>



        {/* Botones */}
        <div className="h-12 w-full flex justify-around items-center bg-primary-50">
          <IconButton>
            <EditIcon
              size={25}
              className="text-secondary"
              strokeWidth={2.5}
              onClick={handleEdit}
            />
          </IconButton>
          <IconButton>
            <TrashIcon
              size={25}
              className="text-secondary"
              strokeWidth={2.5}
              onClick={openModal}
            />
          </IconButton>
        </div>
        <Modal isVisible={modal} onClose={handleOnClose} isDismiseble>
      <div className="bg-white rounded-lg h-full shadow-lg p-5 ">
        <h1 className="text-lg">¿Esta seguro de eliminar esta alerta?</h1>
        <div className="mt-5">
          <Button
            text="Aceptar"
            paddingClassname="py-2 px-10"
            className=" text-sm bg-primary-light"
            onClick={() => handleDelete()}
            isLoading={deleteState === FormState.Submitting}
            disabled={deleteState === FormState.Submitting}
          />
          <Button
            text="Cancelar"
            paddingClassname="py-2 px-10"
            className="text-sm bg-redButton ml-5"
            onClick={handleOnClose}
          />
        </div>
      </div>
    </Modal>
      </motion.div>
    </div>
  );
};
