import { useFormik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import regionesComunas from "../../assets/RegionesComunas.json";
import { AutocompletePlaces } from "../../components/AutocompletePlaces";
import { Button } from "../../components/Button";
import { CustomSelect } from "../../components/CustomSelect";
import { FileSpace } from "../../components/FileSpace";
import { MenuItem } from "../../components/MenuItem";
import { Modal } from "../../components/Modal";
import { Select } from "../../components/Select";
import { TextField } from "../../components/TextField";
import { units } from "../../configs/units";
import { FormState } from "../../models/form_states";
import { getAdvertising } from "../../redux/actions/advertising.action";
import { getCategories } from "../../redux/actions/categories.action";
import {
  addRequest,
  requestAddInitial,
} from "../../redux/actions/requests.actions";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { CategoriesState } from "../../redux/reducers/categories.reducer";
import { RequestsState } from "../../redux/reducers/requests.reducer";
import { RootState } from "../../redux/reducers/root.reducer";

export const AddRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCategories());
    // eslint-disable-next-line
  }, []);

  const { categories } = useSelector<RootState, CategoriesState>(
    (state) => state.categoriesReducer
  );

  const {
    add: { state },
  } = useSelector<RootState, RequestsState>((state) => state.requestReducer);

  const [imgModal, setImgModal] = useState({
    open: false,
    text: "El archivo excede el tamaño maximo",
  });

  const openImgModal = (text: string) => {
    setImgModal({ open: true, text });
  };

  const handleImgCloseModal = () => {
    setImgModal({ open: false, text: "" });
  };

  

  const { handleSubmit, values, handleChange, touched, setFieldValue, errors } =
    useFormik({
      initialValues: {
        Titulo: "",
        Region: "",
        Comuna: "",
        Categoria: "",
        Descripcion: "",
        Direccion: "",
        PlaceId: "",
        Precio: "",
        Unidad: {
          Tipo: { label: "unidad", value: "unidad" },
          Cantidad: 1,
        },
        FechaInicio: "",
        FechaTermino: "",
        Archivos: [] as any,
        Imagenes: [] as any,
        TerminosYCondiciones: null,
        Ubicacion: {},
        Destacar: false,
      },
      onSubmit: (values: any) => {
        // Se coloca de esta forma por como devuelve los datos el Select en "Categoria" y "Unidad.Tipo"
        dispatch(
          addRequest({
            ...values,
            Unidad: {
              Cantidad: values.Unidad.Cantidad,
              Tipo: values.Unidad.Tipo.value,
            },
            Categoria: values.Categoria.value,
            TerminosYCondiciones: Boolean(values.TerminosYCondiciones),
          })
        );
      },
      validationSchema: yup.object().shape({
        Titulo: yup.string().required("Debe ingresar titulo"),
        Region: yup.string().required("Debe ingresar región"),
        Comuna: yup.string().required("Debe ingresar comuna"),
        Categoria: yup.object().required("Debe ingresar categoria"),
        Descripcion: yup.string().required("Debe ingresar descripción"),
        Direccion: yup.string().required("Debe ingresar dirección"),
        Precio: yup.number().required("Debe ingregsar presupuesto"),
        Unidad: yup.object().shape({
          Tipo: yup.object().shape({
            value: yup.string().required("Debe ingresar tipo de unidad"),
          }),
          Cantidad: yup
            .number()
            .min(1, "La cantidad debe ser mayor o igual a 1")
            .required("Debe ingresar unidades"),
        }),
        FechaInicio: yup.string().required("Debe ingresar fecha inicio"),
        FechaTermino: yup.string().required("Debe ingresar fecha termino"),
        TerminosYCondiciones: yup
          .boolean()
          .nullable()
          .required("Debe aceptar los términos y condiciones"),
      }),
    });
  const [maxImages, setMaxImages] = useState(false);
  const handleDeleteImage = (index: number, file: File | string) => {
    const finded = values.Imagenes[index];
    if (!finded) {
      return;
    }
    const newArray = values.Imagenes.filter((item: any) => item !== finded);
    setFieldValue("Imagenes", newArray, false);
  };

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files && files.length > 0 && values.Imagenes.length < 4) {
      const file = files.item(0)!;
      if (file?.size <= 8388608) {
        const newArray = [...values.Imagenes];
        newArray.push(file);
        setFieldValue("Imagenes", newArray);
      } else{
        openImgModal("El archivo supera el tamaño máximo")
      }
    }else {
      openImgModal("Se alcanzó la cantidad máxima de archivos")
    }
  };

  const handleShowTerms = () => {};

  const [modal, setModal] = useState({
    open: false,
    text: "Solicitud creada correctamente",
  });

  const openModal = (text: string) => {
    setModal({ open: true, text });
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    dispatch(getAdvertising());
  }, []);

  useEffect(() => {
    if (state === FormState.Success) {
      openModal("Solicitud creada correctamente");
    }
  }, [state]);

  const handleCloseModal = () => {
    setModal({ open: false, text: "" });
    setTimeout(() => {
      navigate("demandante/solicitudesRealizadas", { replace: true });
      dispatch(requestAddInitial());
    }, 50);
  };

  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Crear Solicitud" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Crear Solicitud" && x.Posicion === "inferior"
  );

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="py-10 px-8 xl:px-10 lg:container lg:mx-auto"
    >
      <Helmet title="Crear Solicitud" />
      <h1 className=" text-3xl font-bold text-deepBlue mb-5 ">
        Crear Solicitud
      </h1>
      {AdvertisingSup.length === 1 && (
        <a href={AdvertisingSup[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingSup[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingSup[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}
        </a>
      )}
      <motion.div>
        <form
          className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full md:mx-5"
          onSubmit={handleSubmit}
        >
          <div className="">
            <TextField
              label="Título"
              placeholder="Título"
              id="Titulo"
              divClassName="mb-4"
              value={values.Titulo}
              onChange={handleChange}
              helperText={touched.Titulo && errors.Titulo}
              error={touched.Titulo && Boolean(errors.Titulo)}
            />

            <CustomSelect
              label="Categoría"
              id="Categoria"
              options={categories.map((categorie) => ({
                label: categorie.Nombre,
                value: categorie.Nombre,
              }))}
              placeholder="Selecciona una categoría"
              onChange={(value: any) => {
                setFieldValue("Categoria", value);
              }}
              value={values.Categoria}
              isSearchable
              autoFocus
            />
            <TextField
              label="¿Cuándo necesitas que se inicie el trabajo?"
              id="FechaInicio"
              type="date"
              divClassName="mb-4 mt-5"
              value={values.FechaInicio}
              onChange={handleChange}
              helperText={touched.FechaInicio && errors.FechaInicio}
              error={touched.FechaInicio && Boolean(errors.FechaInicio)}
            />
            <TextField
              label="¿Cuándo necesitas que se finalice el trabajo?"
              id="FechaTermino"
              type="date"
              divClassName="mb-4"
              value={values.FechaTermino}
              onChange={handleChange}
              helperText={touched.FechaTermino && errors.FechaTermino}
              error={touched.FechaTermino && Boolean(errors.FechaTermino)}
            />
            <TextField
              label="Descripción"
              placeholder="Descripción"
              id="Descripcion"
              divClassName="mb-4"
              autoComplete="off"
              multiline
              value={values.Descripcion}
              onChange={handleChange}
              helperText={touched.Descripcion && errors.Descripcion}
              error={touched.Descripcion && Boolean(errors.Descripcion)}
            />
          </div>
          <div>
            <div className="grid grid-cols-1 gap-4">
              <AutocompletePlaces
                label="Dirección"
                id="Direccion"
                onChange={(place) => {
                  if (place) {
                    const address = place.formatted_address;
                    const placeId = place.place_id;
                    const location = {
                      lat: place.geometry?.location?.lat(),
                      lng: place.geometry?.location?.lng(),
                    };
                    let region = "";
                    let city = "";

                    place.address_components?.forEach((component) => {
                      // Region
                      if (
                        component.types.includes("administrative_area_level_1")
                      ) {
                        region = component.long_name;
                      }
                      // Ciudad
                      if (
                        component.types.includes("administrative_area_level_3")
                      ) {
                        city = component.long_name;
                      }
                    });

                    const findedRegion = regionesComunas.regiones.find((r) =>
                      r.region
                        .toLocaleLowerCase()
                        .match(region.toLocaleLowerCase())
                    );
                    const findedCity = findedRegion?.comunas.find((c) =>
                      c.toLocaleLowerCase().match(city.toLocaleLowerCase())
                    );

                    setFieldValue("Direccion", address, false);
                    setFieldValue("Ubicacion", location, false);
                    setFieldValue("PlaceId", placeId, false);
                    setFieldValue("Region", findedRegion?.region, false);
                    setTimeout(() => {
                      setFieldValue("Comuna", findedCity, false);
                    }, 100);
                  }
                }}
              />
              <Select
                id="Region"
                fullWidth
                label="Región"
                placeholder="Seleccionar Región"
                helperText={touched.Region && errors.Region}
                error={touched.Region && Boolean(errors.Region)}
                value={values.Region}
                onChange={(value) => {
                  setFieldValue("Region", value);
                  setFieldValue("Comuna", "");
                }}
              >
                {regionesComunas.regiones.map((region, index) => {
                  return (
                    <MenuItem
                      key={`region-${index}`}
                      value={region.region}
                      label={region.region}
                    />
                  );
                })}
              </Select>
              <Select
                id="Comuna"
                fullWidth
                label="Comuna"
                placeholder="Seleccionar Comuna"
                value={values.Comuna}
                helperText={touched.Comuna && errors.Comuna}
                error={touched.Comuna && Boolean(errors.Comuna)}
                onChange={(value) => {
                  setFieldValue("Comuna", value);
                }}
              >
                {values.Region &&
                  regionesComunas.regiones
                    .find((x) => x.region === values.Region)
                    ?.comunas?.map((comuna, index) => (
                      <MenuItem
                        key={`comuna-${index}`}
                        value={comuna}
                        label={comuna}
                      />
                    ))}
              </Select>

              <div className="grid grid-cols-3 gap-4">
                <TextField
                  label="Presupuesto"
                  placeholder="Precio"
                  id="Precio"
                  value={values.Precio}
                  onChange={handleChange}
                  helperText={touched.Precio && errors.Precio}
                  error={touched.Precio && Boolean(errors.Precio)}
                  type="number"
                />
                <CustomSelect
                  label="Unidad"
                  id="Unidad.Tipo"
                  options={units.map((unit) => ({
                    label: unit,
                    value: unit,
                  }))}
                  placeholder="Unidad"
                  onChange={(value: any) => {
                    setFieldValue("Unidad.Tipo", value);
                  }}
                  value={values.Unidad.Tipo}
                  isSearchable
                />
                <TextField
                  label="Cantidad"
                  placeholder="Cantidad"
                  id="Unidad.Cantidad"
                  value={values.Unidad.Cantidad}
                  onChange={handleChange}
                  helperText={
                    touched?.Unidad?.Cantidad && errors?.Unidad?.Cantidad
                  }
                  error={
                    touched?.Unidad?.Cantidad &&
                    Boolean(errors?.Unidad?.Cantidad)
                  }
                  type="number"
                />
              </div>
              <div className="flex flex-col">
                <TextField
                  label="Subir imágenes"
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={handleChangeImage}
                />
                <p className="text-gray-400 mt-2 mb-2">
                  *Máximo 4 imágenes hasta 8 MB cada una.
                </p>
                <div className="flex flex-wrap justify-center items-center">
                  <FileSpace
                    urls={values.Imagenes.map(
                      (img: any) =>
                        img instanceof File && URL.createObjectURL(img)
                    )}
                    handleDeleteFile={handleDeleteImage}
                    imagen
                    disabled={false}
                  />
                </div>
              </div>
              <div className="">
                <label className="flex items-center " htmlFor="">
                  <input
                    id="TerminosYCondiciones"
                    className="h-5 w-5 mr-3 "
                    type="checkbox"
                    checked={Boolean(values.TerminosYCondiciones)}
                    onChange={(event) =>
                      setFieldValue(
                        "TerminosYCondiciones",
                        !values.TerminosYCondiciones
                      )
                    }
                  />
                  <p className="text-gray-400 font-semibold flex-1">
                    Acepto los
                    <span
                      className="text-primary cursor-pointer"
                      onClick={handleShowTerms}
                    >
                      &nbsp;Términos y Condiciones
                    </span>
                  </p>
                </label>
                {touched.TerminosYCondiciones &&
                  Boolean(errors.TerminosYCondiciones) && (
                    <p className="text-sm font-semibold text-red-500">
                      {errors.TerminosYCondiciones}
                    </p>
                  )}
                <Button
                  type="submit"
                  text="Crear Solicitud"
                  color="secondary"
                  fullWidth
                  className="mt-10"
                  isLoading={state === FormState.Submitting}
                  disabled={state === FormState.Submitting}
                />
              </div>
            </div>
          </div>
        </form>
        {AdvertisingBot.length === 1 && (
          <a href={AdvertisingBot[0].Link} target={"_blank"}>
            {actualWidth > 400 ? (
              <img
                src={AdvertisingBot[0].Imagen.URL}
                className="mx-auto rounded-md mt-5 object-cover   md:visible lg:visible"
                style={{ width: 1309, height: 150 }}
              ></img>
            ) : (
              <img
                src={AdvertisingBot[0].ImagenMovil.URL}
                className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
                style={{ width: 1309, height: 150 }}
              ></img>
            )}{" "}
          </a>
        )}
      </motion.div>
      <Modal isVisible={modal.open} onClose={handleCloseModal}>
        <div className="bg-white w-full rounded-lg text-center p-4">
          <p className="text-lg font-semibold">{modal.text}</p>
          <Button
            text={"Ok"}
            className="mt-3 bg-primary text-md"
            paddingClassname="py-1 px-5"
            onClick={handleCloseModal}
            textColor="text-white"
          />
        </div>
      </Modal>
      <Modal isVisible={imgModal.open} onClose={handleImgCloseModal}>
        <div className="bg-white w-full rounded-lg text-center p-4">
          <p className="text-lg font-semibold">
            {imgModal.text}
          </p>
          <Button
            text={"Ok"}
            className="mt-3 bg-primary text-md"
            paddingClassname="py-1 px-5"
            onClick={handleImgCloseModal}
            textColor="text-white"
          />
        </div>
      </Modal>
    </motion.main>
  );
};
