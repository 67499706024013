import { combineReducers } from "redux";

import authReducer from "./auth.reducer";
import requestReducer from "./requests.reducer";
import categoriesReducer from "./categories.reducer";
import transactionReducer from "./transaction.reducer";
import offerReducer from "./offer.reducer";
import suscriptionReducer from "./suscription.reducer";
import statisticReducer from "./statistic.reducer";
import alertReducer from "./alert.reducer";
import advertisingReducer from "./advertising.reducer";
import calificationReducer from "./calification.reducer";

export const rootReducer = combineReducers({
  requestReducer,
  authReducer,
  categoriesReducer,
  transactionReducer,
  offerReducer,
  suscriptionReducer,
  statisticReducer,
  alertReducer,
  advertisingReducer,
  calificationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
