import react, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import KeyOutlinedIcon from "../assets/icons/KeyOutlinedIcon";
import UserOutlinedIcon from "../assets/icons/UserOutlinedIcon";
import { Button } from "../components/Button";
import { TextField } from "../components/TextField";
import { FormState } from "../models/form_state";
import { resetContraseña, resetPasswordInitial } from "../redux/actions/auth.actions";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { Modal } from "../components/Modal";
import { useNavigate } from "react-router-dom";

export const RecoveryPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    open: false,
    text: "Se ha enviado un correo para recuperar la contraseña",
  });
  const openModal = (text: string) => {
    setModal({ open: true, text });
  };

  const handleCloseModal = () => {
    setModal({ open: false, text: "" });
    setTimeout(() => {
      navigate("/", { replace: true });
      dispatch(resetPasswordInitial())
    }, 50);
  };

  const {
    resetPassword: {  state, error  },
  } = useSelector<RootState, AuthState>((state) => state.authReducer);
  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: {
      Email: "",
      Password: "",
    },
    onSubmit: (values) => {
      dispatch(resetContraseña(values));
    },
    validationSchema: yup.object().shape({
      Email: yup
        .string()
        .required("Correo requerido")
        .email("Correo no válido"),
    }),
  });

  useEffect(() => {
    if (state === FormState.Success) {
      openModal("Se ha enviado un correo para recuperar la contraseña");
    }
  }, [state]);

  return (
    <div className="flex flex-col pb-6 w-full">
      <div className="flex flex-col items-center">
        <p className="font-bold text-deepBlue pb-8 text-3xl pt-5">
          Ingresa tu correo
        </p>
        <form onSubmit={handleSubmit} className="sm:w-1/4">
          <TextField
            id="Email"
            placeholder="Correo"
            type="email"
            icon={<UserOutlinedIcon height={28} width={28} />}
            divClassName="mb-5 w-full"
            outlined={false}
            value={values.Email}
            onChange={handleChange}
            helperText={touched.Email && errors.Email}
            error={touched.Email && Boolean(errors.Email)}
          />
          <Button
            text="Recuperar contraseña"
            color="secondary"
            className="w-full mt-5"
            fullWidth
            type="submit"
            isLoading={state === FormState.Submitting}
          />
        </form>
      </div>
      <Modal isVisible={modal.open} onClose={handleCloseModal}>
        <div className="bg-white w-full rounded-lg text-center p-4">
          <p className="text-sm font-semibold">{modal.text}</p>
          <Button
            text={"Ok"}
            className="mt-3 bg-primary-light text-white font-semibold"
            paddingClassname="py-1 px-3"
            onClick={handleCloseModal}
          />
        </div>
      </Modal>
    </div>
  );
};
