import React from "react";
import FacebookIcon from "../assets/icons/FacebookIcon";
import InstagramIcon from "../assets/icons/InstagramIcon";
import TwitterIcon from "../assets/icons/TwitterIcon";
import YoutubeIcon from "../assets/icons/YoutubeIcon";
import logoEMP from "../assets/images/LogoEMP.png";
import fotoHector from "../assets/images/hector.jpg";

export const Contact = () => {
  return (
    <div className="flex flex-col pb-6">
      <div
        className="object-cover bg-imagen-quienes-somos bg-cover h-96 w-full items-center flex justify-center left-1/2 "
        style={{
          height: "500px",
          backgroundPositionX: "center",
        }}
      >
        <p className="text-3xl text-center text-white font-bold">
          ¿Tienes preguntas o comentarios?
          <br />
          ¡Contáctanos! Estamos aquí para ayudarte.
        </p>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col mt-12 items-center mb-10">
          <div>
            <span className="mr-4 font-bold text-deepBlue text-4xl">
              DemandApp
            </span>
          </div>
          <div className="flex flex-row mt-2">
            <div className="mr-5">
              <FacebookIcon height={32} width={32} />
            </div>
            <div className="mr-5 ">
              <InstagramIcon height={32} width={32} />
            </div>
            <div className="mr-5">
              <TwitterIcon height={32} width={32} />
            </div>
            <div className="">
              <YoutubeIcon height={32} width={32} />
            </div>
          </div>
        </div>

        <p className="w-11/12 text-2xl text-center mb-7 text-gray-400 font-bold">
          Para contactarse con nosotros, por favor envíanos un correo
          electrónico a la siguiente dirección:
        </p>

        <a
          className="font-bold text-deepBlue pb-8 text-3xl pt-5"
          href="mailto:contacto@demandapp.cl"
        >
          contacto@demandapp.cl
        </a>
        <p className="w-11/12 text-2xl text-center mb-7 text-gray-400 font-bold">
          Nos pondremos en contacto con usted lo antes posible.
        </p>
      </div>
    </div>
  );
};
