import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllRequestsByLocation } from "../redux/actions/requests.actions";

interface Location {
  lat: number;
  lng: number;
}

interface ReturnProps {
  getLocation: VoidFunction;
  currentLocation?: Location;
}

export const useLocation = (): ReturnProps => {
  const [currentLocation, setCurrentLocation] = useState<Location>();

  const dispatch = useDispatch()
  const getLocation = useCallback(async () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          dispatch(getAllRequestsByLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }));

        } catch (error: any) {
          console.log(error);
        }
      },
      (error) => {
        console.log("error:", error);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 1000,
        timeout: 27000,
      }
    );
    // eslint-disable-next-line
  }, []);

  return {
    getLocation,
    currentLocation,
  };
};
