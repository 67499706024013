import { addDays, format, isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Button";
import { IconButton } from "../../components/IconButton";
import { Modal } from "../../components/Modal";
import { FormState } from "../../models/form_state";
import { getAdvertising } from "../../redux/actions/advertising.action";
import {
  getMoreTransactionPlaintiff,
  getTransactionPlaintiff,
} from "../../redux/actions/transaction.action";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { RootState } from "../../redux/reducers/root.reducer";
import { TransactionState } from "../../redux/reducers/transaction.reducer";
import { TransactionCard } from "./List/components/TransactionCard";

export const MyTransaction = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<any>();

  const dispatch = useDispatch();
  const { transactions, state, error, totalDocs } = useSelector<
    RootState,
    TransactionState
  >((state) => state.transactionReducer);

  const moreTransactions = (total: any) => {
    dispatch(getMoreTransactionPlaintiff(total));
  };

  const handleSeeDetails = (transaction: any) => {
    setSelected(transaction);
    setOpen(true);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  // TODO: borrar esta effect
  useEffect(() => {
    dispatch(getTransactionPlaintiff());
    // eslint-disable-next-line
  }, []);

  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);

  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Mis Transacciones" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Mis Transacciones" && x.Posicion === "inferior"
  );

  return (
    <main className="py-10 px-8 lg:container lg:mx-auto">
      {state === FormState.Failure && <p>{error}</p>}
      <Helmet title="Mis Transacciones" />
      <h1 className=" text-3xl font-bold text-deepBlue ">Mis Transacciones</h1>
      {AdvertisingSup.length === 1 && (
        <a href={AdvertisingSup[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingSup[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingSup[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}
        </a>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-8 xl:grid-cols-4 2xl:grid-cols-5">
        <React.Fragment>
          {state === FormState.Success
            ? transactions.map((transaction, index) => (
                <TransactionCard
                  key={`transaction-card-${index}`}
                  transaction={transaction}
                  onSeeDetails={handleSeeDetails}
                />
              ))
            : new Array(5).fill(0).map((value, i) => {
                return (
                  <div
                    className="h-96 bg-white shadow-md rounded-xl overflow-hidden"
                    key={`skeleton-div-${i}`}
                  >
                    <div className="w-full h-1/2 bg-gray-200 animate-pulse"></div>

                    <div className="w-full h-1/2 flex flex-col ">
                      <div className="w-3/4 h-4 pulse rounded-full bg-gray-200 mt-2 mx-4"></div>
                      <div className="mx-4 mt-10">
                        <div className="w-3/4 h-3 pulse rounded-full bg-gray-200"></div>
                        <div className="w-2/4 h-2 pulse rounded-full bg-gray-200 mt-2"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </React.Fragment>
      </div>
      <div className="mb-3 flex items-center justify-center ">
        <Button
          type="submit"
          text="Cargar más transacciones"
          color="secondary"
          className="mt-10  flex items-center justify-center "
          onClick={() => moreTransactions(totalDocs)}
        />
      </div>
      {AdvertisingBot.length === 1 && (
        <a href={AdvertisingBot[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingBot[0].Imagen.URL}
              className="mx-auto rounded-md mt-5 object-cover"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingBot[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}{" "}
        </a>
      )}
      <Modal isVisible={open} onClose={handleOnClose} isDismiseble>
        <div className="bg-white rounded-lg h-full shadow-lg pl-5 pb-5">
          <div className="flex flex-col items-left mb-3 mr-3">
            <IconButton className="ml-auto" onClick={handleOnClose}>
              <X size={32} className="text-secondary" />
            </IconButton>
            {selected && (
              <React.Fragment>
                <div className="font-semibold text-xl text-blue-400">{`Aviso #${selected.Solicitud.Id}`}</div>
                {isAfter(
                  selected.FechaCreacion.toDate(),
                  selected.FechaPremium.toDate()
                ) ? (
                  <h3 className="text-sm text-red-600">
                    {`Vigencia: ${format(
                      selected.FechaCreacion.toDate(),
                      "dd/MM/yyyy"
                    )} - ${format(
                      selected.FechaPremium.toDate(),
                      "dd/MM/yyyy"
                    )}`}
                  </h3>
                ) : (
                  <h3 className="text-sm text-gray-400">
                    {`Vigencia: ${format(
                      selected.FechaCreacion.toDate(),
                      "dd/MM/yyyy"
                    )} - ${format(
                      selected.FechaPremium.toDate(),
                      "dd/MM/yyyy"
                    )}`}
                  </h3>
                )}
                <div className="text-sm text-gray-400">
                  Fecha de pago:{" "}
                  {format(selected.FechaCreacion.toDate(), "dd/MM/yyyy")}
                </div>
                <div className="text-sm text-gray-400">{`Monto: ${selected.Precio}`}</div>
                <div className="text-sm text-gray-400">{`N° transacción: ${selected.Transbank.buy_order}`}</div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Modal>
    </main>
  );
};
