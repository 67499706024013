import { useFormik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import regionesComunas from "../../assets/RegionesComunas.json";
import { AutocompletePlaces } from "../../components/AutocompletePlaces";
import { Button } from "../../components/Button";
import { CustomSelect } from "../../components/CustomSelect";
import { FileSpace } from "../../components/FileSpace";
import { MenuItem } from "../../components/MenuItem";
import { Modal } from "../../components/Modal";
import { Select } from "../../components/Select";
import { TextField } from "../../components/TextField";
import { FormState } from "../../models/form_states";
import { getCategories } from "../../redux/actions/categories.action";
import {
  editRequest,
  editRequestInitial,
} from "../../redux/actions/requests.actions";
import { CategoriesState } from "../../redux/reducers/categories.reducer";
import { RequestsState } from "../../redux/reducers/requests.reducer";
import { RootState } from "../../redux/reducers/root.reducer";

export const EditRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCategories());
    // eslint-disable-next-line
  }, []);

  const { categories } = useSelector<RootState, CategoriesState>(
    (state) => state.categoriesReducer
  );

  const {
    selected,
    edit: { state },
  } = useSelector<RootState, RequestsState>((state) => state.requestReducer);

  const [imgModal, setImgModal] = useState({
    open: false,
    text: "El archivo excede el tamaño maximo",
  });

  const openImgModal = (text: string) => {
    setImgModal({ open: true, text });
  };

  const handleImgCloseModal = () => {
    setImgModal({ open: false, text: "" });
  };

  

  const { handleSubmit, values, handleChange, touched, setFieldValue, errors } =
    useFormik({
      initialValues: {
        Titulo: selected?.Titulo ? selected.Titulo : "",
        Region: selected?.Region ? selected.Region : "",
        Comuna: selected?.Comuna ? selected.Comuna : "",
        Categoria: selected?.Categoria
          ? { label: selected.Categoria, value: selected.Categoria }
          : "",
        Descripcion: selected?.Descripcion ? selected.Descripcion : "",
        Direccion: selected?.Direccion ? selected.Direccion : "",
        PlaceId: selected?.PlaceId ? selected.PlaceId : "",
        Precio: selected?.Precio ? selected.Precio : 0,
        Unidad: {
          Tipo: selected?.Unidad.Tipo ? selected.Unidad.Tipo : "",
          Cantidad: selected?.Unidad.Cantidad ? selected.Unidad.Cantidad : 0,
        },
        FechaInicio: selected?.FechaInicio ? selected.FechaInicio : "",
        FechaTermino: selected?.FechaTermino ? selected.FechaTermino : "",
        Ubicacion: selected?.Ubicacion
          ? selected.Ubicacion
          : { lat: 0, lng: 0 },
        Imagenes: selected?.Imagenes ? selected.Imagenes : ([] as any),
      },
      onSubmit: (values) => {
        dispatch(
          editRequest({
            ...values,
            id: selected!.id,
            Usuario: selected?.Usuario,
          })
        );
      },
      validationSchema: yup.object({
        Titulo: yup.string().required("Debe ingresar titulo"),
        Region: yup.string().required("Debe ingresar región"),
        Comuna: yup.string().required("Debe ingresar comuna"),
        Categoria: yup.object().required("Debe ingresar categoria"),
        Descripcion: yup.string().required("Debe ingresar descripción"),
        Direccion: yup.string().required("Debe ingresar dirección"),
        Precio: yup.number().required("Debe ingregsar presupuesto"),
        Unidad: yup.object({
          Tipo: yup.string().required("Debe ingresar tipo de unidad"),
          Cantidad: yup
            .number()
            .min(0, "La cantidad debe ser mayor o igual a 0")
            .required("Debe ingresar unidades"),
        }),
        FechaInicio: yup.string().required("Debe ingresar fecha inicio"),
        FechaTermino: yup.string().required("Debe ingresar fecha termino"),
      }),
    });

  // eslint-disable-next-line
  const [modal, setModal] = useState(false);
  const [maxImages, setMaxImages] = useState(false)

  const handleDeleteImage = (index: number, file: File | string) => {
    const finded = values.Imagenes[index];
    if (!finded) {
      return;
    }
    const newArray = values.Imagenes.filter((item: any) => item !== finded);
    //newArray.filter()
    //newArray.splice(index, 1);
    setFieldValue("Imagenes", newArray, false);
  };

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files && files.length > 0 && values.Imagenes.length < 4) {
      const file = files.item(0)!;
      if (file?.size <= 8388608) {
        const newArray = [...values.Imagenes];
        newArray.push(file);
        setFieldValue("Imagenes", newArray);
      }else{
        openImgModal("El archivo supera el tamaño máximo")
      }
    }else {
      openImgModal("Se alcanzó la cantidad máxima de archivos")
    }
  };

  const handleCloseEdit = () => {
    setModal(false)
    navigate("/demandante/solicitudesRealizadas");
  };

  const handleCloseMaxImages = () => {
    setMaxImages(false)
  };

  useEffect(() => {
    if (state === FormState.Success) {
      //TODO arreglar escucha del state
      //openModal();
      setModal(true);
      //dispatch(editRequestInitial());
    }
    // eslint-disable-next-line
  }, [state]);

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="py-10 px-8 lg:container lg:mx-auto"
    >
      <Helmet title="Editar Solicitud" />
      <h1 className=" text-3xl font-bold text-deepBlue mb-5">
        Editar Solicitud
      </h1>
      <motion.div>
        <form
          className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full"
          onSubmit={handleSubmit}
        >
          <div className="">
            <TextField
              label="Titulo"
              placeholder="Titulo"
              id="Titulo"
              divClassName="mb-4"
              value={values.Titulo}
              onChange={handleChange}
              helperText={touched.Titulo && errors.Titulo}
              error={touched.Titulo && Boolean(errors.Titulo)}
            />
            {/* Cambiar por un autocomplete */}
            <CustomSelect
              label="Categoria"
              id="Categoria"
              options={categories.map((categorie) => ({
                label: categorie.Nombre,
                value: categorie.Nombre,
              }))}
              placeholder="Selecciona una categoria"
              onChange={(value: any) => {
                setFieldValue("Categoria", value);
              }}
              value={values.Categoria}
              isSearchable
              autoFocus
            />
            <TextField
              label="Descripcion"
              placeholder="Descripcion"
              id="Descripcion"
              divClassName="mb-4"
              autoComplete="off"
              multiline
              value={values.Descripcion}
              onChange={handleChange}
              helperText={touched.Descripcion && errors.Descripcion}
              error={touched.Descripcion && Boolean(errors.Descripcion)}
            />
            <TextField
              label="Presupuesto"
              placeholder="Precio"
              id="Precio"
              divClassName="mb-4"
              value={values.Precio}
              onChange={handleChange}
              helperText={touched.Precio && errors.Precio}
              error={touched.Precio && Boolean(errors.Precio)}
              type="number"
            />
            <TextField
              label="¿Cuando necesitas que se inicie el trabajo?"
              id="FechaInicio"
              type="date"
              divClassName="mb-4"
              value={values.FechaInicio}
              onChange={handleChange}
              helperText={touched.FechaInicio && errors.FechaInicio}
              error={touched.FechaInicio && Boolean(errors.FechaInicio)}
            />
            <TextField
              label="¿Cuando necesitas que se finalize el trabajo?"
              id="FechaTermino"
              type="date"
              divClassName="mb-4"
              value={values.FechaTermino}
              onChange={handleChange}
              helperText={touched.FechaTermino && errors.FechaTermino}
              error={touched.FechaTermino && Boolean(errors.FechaTermino)}
            />
          </div>
          <div className="">
            <div className="mb-10">
              <div>
                <AutocompletePlaces
                  label="Dirección"
                  id="Direccion"
                  value={values.Direccion}
                  onChange={(place) => {
                    if (place) {
                      const address = place.formatted_address;
                      const placeId = place.place_id;
                      const location = {
                        lat: place.geometry?.location?.lat(),
                        lng: place.geometry?.location?.lng(),
                      };
                      let region = "";
                      let city = "";

                      place.address_components?.forEach((component) => {
                        // Region
                        if (
                          component.types.includes(
                            "administrative_area_level_1"
                          )
                        ) {
                          region = component.long_name;
                        }
                        // Ciudad
                        if (
                          component.types.includes(
                            "administrative_area_level_3"
                          )
                        ) {
                          city = component.long_name;
                        }
                      });

                      const findedRegion = regionesComunas.regiones.find((r) =>
                        r.region
                          .toLocaleLowerCase()
                          .match(region.toLocaleLowerCase())
                      );
                      const findedCity = findedRegion?.comunas.find((c) =>
                        c.toLocaleLowerCase().match(city.toLocaleLowerCase())
                      );
                      setFieldValue("Direccion", address, false);
                      setFieldValue("Ubicacion", location, false);
                      setFieldValue("PlaceId", placeId, false);
                      setFieldValue("Region", findedRegion?.region, false);
                      setTimeout(() => {
                        setFieldValue("Comuna", findedCity, false);
                      }, 100);
                    }
                  }}
                />
              </div>
              <div className="mt-4">
                <Select
                  id="Region"
                  fullWidth
                  label="Región"
                  placeholder="Seleccionar Región"
                  helperText={touched.Region && errors.Region}
                  error={touched.Region && Boolean(errors.Region)}
                  value={values.Region}
                  onChange={(value) => {
                    setFieldValue("Region", value);
                    setFieldValue("Comuna", "");
                  }}
                >
                  {regionesComunas.regiones.map((region, index) => {
                    return (
                      <MenuItem
                        key={`region-${index}`}
                        value={region.region}
                        label={region.region}
                      />
                    );
                  })}
                </Select>
              </div>
              <div className="mt-4">
                <Select
                  id="Comuna"
                  fullWidth
                  label="Comuna"
                  placeholder="Seleccionar Comuna"
                  value={values.Comuna}
                  helperText={touched.Comuna && errors.Comuna}
                  error={touched.Comuna && Boolean(errors.Comuna)}
                  onChange={(value) => {
                    setFieldValue("Comuna", value);
                  }}
                >
                  {values.Region &&
                    regionesComunas.regiones
                      .find((x) => x.region === values.Region)
                      ?.comunas?.map((comuna, index) => (
                        <MenuItem
                          key={`comuna-${index}`}
                          value={comuna}
                          label={comuna}
                        />
                      ))}
                </Select>
              </div>
              <div className="mt-4">
                <div className="grid grid-cols-1 gap-4">
                  <Select
                    id="Unidad.Tipo"
                    label="Unidad"
                    placeholder="Seleccionar Unidad"
                    value={values.Unidad.Tipo}
                    helperText={touched?.Unidad?.Tipo && errors?.Unidad?.Tipo}
                    error={
                      touched?.Unidad?.Tipo && Boolean(errors?.Unidad?.Tipo)
                    }
                    onChange={(value) => {
                      setFieldValue("Unidad.Tipo", value);
                    }}
                  >
                    {[
                      "milímetro",
                      "centímetro",
                      "pulgada",
                      "metro",
                      "kilómetro",
                      "metro cuadrado",
                      "hectárea",
                      "kilómetro cuadrado",
                      "centímetro cúbico",
                      "metro cúbico",
                      "mililitro",
                      "litro",
                      "gramo",
                      "kilogramo",
                      "tonelada",
                      "minuto",
                      "hora",
                      "kilovatio-hora",
                      "día",
                      "unidad",
                      "mes",
                      "año",
                    ].map((Unidad, index) => (
                      <MenuItem
                        key={`Unidad-${index}`}
                        value={Unidad}
                        label={Unidad}
                      />
                    ))}
                  </Select>
                  <TextField
                    label="Cantidad"
                    placeholder="Cantidad"
                    id="Unidad.Cantidad"
                    value={values.Unidad.Cantidad}
                    onChange={handleChange}
                    helperText={
                      touched?.Unidad?.Cantidad && errors?.Unidad?.Cantidad
                    }
                    error={
                      touched?.Unidad?.Cantidad &&
                      Boolean(errors?.Unidad?.Cantidad)
                    }
                    type="number"
                  />
                  <div className="flex flex-col pb-10">
                    <TextField
                      label="Subir imágenes"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleChangeImage}
                    />
                    <p className="text-gray-400 mt-2 mb-2">
                      *Máximo 4 imagenes, Peso max 8 mb por imagen.
                    </p>
                    <div className="flex flex-wrap justify-center items-center">
                      <FileSpace
                        urls={values.Imagenes.map((img: any) =>
                          img instanceof File
                            ? URL.createObjectURL(img)
                            : img.URL
                        )}
                        handleDeleteFile={handleDeleteImage}
                        imagen
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                <Button
                  type="submit"
                  paddingClassname="p-4"
                  text="Modificar Solicitud"
                  color="secondary"
                  className="mt-10 ml-36"
                  isLoading={state === FormState.Submitting}
                  disabled={state === FormState.Submitting}
                />
                <Button
                  type="reset"
                  paddingClassname="p-4"
                  text="Cancelar Cambios"
                  onClick={handleCloseEdit}
                  className="mt-10 bg-redButton ml-8"
                  disabled={state === FormState.Submitting}
                />
              </div>
            </div>
          </div>
        </form>
      </motion.div>
      <Modal isVisible={modal} onClose={handleCloseEdit}>
        <div className="bg-white w-full rounded-lg text-center p-4">
          <p className="text-sm font-semibold">
            {"Se ha modificado la solicitud"}
          </p>
          <Button
            text="Aceptar"
            paddingClassname="py-2 px-7"
            className=" text-sm bg-primary-light mt-3"
            onClick={handleCloseEdit}
          />
        </div>
      </Modal>
      <Modal isVisible={imgModal.open} onClose={handleImgCloseModal}>
        <div className="bg-white w-full rounded-lg text-center p-4">
          <p className="text-lg font-semibold">
            {"Se ha alcanzado el máximo de imagenes"}
          </p>
          <Button
            text="Aceptar"
            paddingClassname="py-2 px-7"
            className=" text-sm bg-primary-light mt-3"
            onClick={handleImgCloseModal}
          />
        </div>
      </Modal>
    </motion.main>
  );
};
