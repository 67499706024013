import clsx from "clsx";
import { motion } from "framer-motion";
import React from "react";

interface Props {
  severity?: "info" | "error" | "warning" | "success";
  className?: string;
}

export const Alert: React.FC<Props> = ({
  severity = "info",
  className = "",
  children,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6, type: "spring" }}
      className={clsx(
        "w-full rounded-lg flex items-center p-5",
        severity === "info" && "bg-secondary bg-opacity-10 text-secondary-dark",
        severity === "error" && "bg-red-200 text-red-600",
        severity === "warning" && "bg-yellow-200 text-yellow-600",
        severity === "success" && "bg-green-200 text-green-600",
        className
      )}
    >
      <span className="text-sm font-semibold">{children}</span>
    </motion.div>
  );
};
