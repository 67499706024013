import clsx from "clsx";
import React from "react";
import Select, { StylesConfig } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

interface Props extends StateManagerProps {
  error?: string | boolean;
  label?: string;
  helperText?: any;
  helperTextClassName?: string;
  className?:string;
}
function customTheme(theme: any) {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#BFE5FF",
    },
  };
}

const customStyle: StylesConfig = {
  control: (provided: any) => ({
    ...provided,
    border: "1px solid #009fda",
    borderColor: "black",
    borderRadius: "30px",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover":{
      borderColor: "#009fda"
    }
  }),
};
const customStyle2: StylesConfig = {
  control: (provided: any) => ({
    ...provided,
    height: "45px",
    border: "1px solid #009fda",
    borderColor: "black",
    borderRadius: "30px",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover":{
      borderColor: "#009fda"
    }
  }),
};
export const CustomSelect: React.FC<Props> = ({ helperTextClassName,helperText,error, label, styles, theme,className, ...others }) => {
  
  return (
    <div className={className}>
      <label htmlFor="" className="text-deepBlue text-lg font-semibold ml-4">
        {label}
      </label>
      <Select {...others} styles={others.isMulti ? customStyle:customStyle2} theme={customTheme} />
      <p>{error}</p>
      {helperText && error && (
        <div className="px-5">
          <span
            className={clsx(
              "font-semibold text-left",
              helperTextClassName,
              error && "text-red-500"
            )}
            style={{ color: helperText }}
          >
            {helperText}
          </span>
        </div>
      )}
    </div>
    
  );
};
