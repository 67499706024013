import * as types from "../types";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";
import { Calification } from "../../models/Calification";
import { Form } from "formik";

export interface CalificationState {
  califications: Calification[];
  state: FormState;
  error?: string;
  lastDoc?: any;
  calificationIdUser?:string;
  totalDocs: number;
  add: {
    error?: string;
    state: FormState;
  };
}

const initialState: CalificationState = {
  califications: [],
  state: FormState.Initial,
  totalDocs: 0,
  add: {
    state: FormState.Initial,
  },
};


export default function reducer(
    state = initialState,
    action: Action
) : CalificationState {
    switch (action.type) {
        case types.CALIFICATION_GET_SUCCESS:
            return {
                ...state,
                califications: action.payload.califications,
                state: FormState.Success,
                lastDoc: action.payload.lastDoc,
                totalDocs: action.payload.totalDocs

            };
        case types.CALIFICATION_GET_FAILURE:
            return {
                ...state,
                state: FormState.Failure,
                error: action.payload
            }
        case types.CALIFICATION_GET_SUBMITTING:
            return {
                ...state,
                state: FormState.Submitting,
                error: undefined
            }
        case types.CALIFICATION_ADD_SUBMITTING:
            return {
                ...state,
                add: {
                    state: FormState.Submitting,
                    error: undefined
                }
            }
        case types.CALIFICATION_ADD_SUCCESS:
            return {
                ...state,
                add: {
                    state: FormState.Success
                }
            }
        case types.CALIFICATION_ADD_FAILURE:
            return {
                ...state,
                add: {
                    state: FormState.Failure,
                    error: action.payload
                }
            }
            case types.CALIFICATION_ID_SUCCESS:
            return {
                ...state,
                calificationIdUser:action.payload
            }
        default:
            return state;
    }
}