import clsx from "clsx";
import { format, isBefore } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Star } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { PlaintiffOffer } from "../../models/PlaintiffOffer";
import { CalificationState } from "../../redux/reducers/calification.reducer";
import { RootState } from "../../redux/reducers/root.reducer";
import { Button } from "../Button";

interface Props {
  offer: PlaintiffOffer;
  handleAccept: (offer: PlaintiffOffer) => void;
}

export const OfferCard: React.FC<Props> = ({ offer, handleAccept }) => {
  const [open, setOpen] = useState(false);

  /*  const handleOnClose = () => {
    toogle();
  }; */

  const toogle = () => {
    setOpen(!open);
  };
  
  const isFeatured = () =>
    offer.Usuario.FechaPremium
      ? isBefore(new Date(), offer.Usuario.FechaPremium.toDate())
      : false;

  return (
    <div className="relative overflow-visible px-5 mb-2">
      <div
        className={clsx(
          "rounded-2xl p-5 relative",
          isFeatured() ? "bg-greenOffer" : "bg-primary-50"
        )}
        style={{ zIndex: 5 }}
      >
        {isFeatured() && (
          <Star className="absolute right-5 top-5 text-deepBlue" size={28} />
        )}
        <div>
          <span className="text-deepBlue text-xl font-bold">
            {offer.Titulo}
          </span>
        </div >
        
        <div className=" flex flex-col text-gray-400 text-base font-semibold ">
          
            <span className="text-primary text-base">
              {`${offer.Usuario.Nombre} ${offer.Usuario.Apellido}`}
            </span>
            
            <span className="text-deepBlue text-lg flex flex-row items-center">
              <Star size={18} />
              {offer.Usuario.Calificacion
                ? `(${offer.Usuario.Calificacion})`
                : "0"}
            </span>
            <span>{` · ${offer.Descripcion}`}</span>
            <span>{` · ${offer.Usuario.Comuna}, ${offer.Usuario.Region}`}</span>
          
          <p>
            <span>{`Fecha Inicio: ${format(
              new Date(offer?.FechaInicio),
              "dd/MM/yyyy"
            )}`}</span>
            <span>{` · Fecha Fin: ${format(
              new Date(offer?.FechaTermino),
              "dd/MM/yyyy"
            )}`}</span>
          </p>
          <p>
            <span>{`Cantidad: ${offer?.Cantidad?.Cantidad} ${offer?.Cantidad?.Tipo}`}</span>
          </p>
        </div>
        <div>
          <h1 className="text-lg text-blue-800 font-bold">{`Precio: $${offer.Precio.toLocaleString(
            "es"
          )}`}</h1>
        </div>
        <div className="flex flex-row justify-center">
          <Button
            text={"Ver Detalle Oferta"}
            paddingClassname="py-2 px-10"
            className="text-sm bg-primary"
            onClick={toogle}
            hidden={open}
          />
        </div>
      </div>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {open && (
          <motion.div
            initial={{ opacity: 0, y: "-50%", zIndex: -1 }}
            animate={{ opacity: 1, y: "-8%", zIndex: -1 }}
            exit={{ opacity: 0, y: "-50%", zIndex: -1 }}
            transition={{
              type: "just",
            }}
            className="bg-grayContainer px-5 pb-6 pt-12 rounded-bl-2xl rounded-br-2xl"
            style={{ zIndex: -1 }}
          >
            <div>{offer.Mensaje}</div>
            <div className="flex flex-row justify-center mt-5">
              <Button
                text={"Aceptar Oferta"}
                paddingClassname="py-2 px-10"
                className="text-sm bg-greenDem"
                onClick={() => handleAccept(offer)}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
