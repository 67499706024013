import clsx from "clsx";
import { isAfter } from "date-fns";
import { motion } from "framer-motion";
import React, { useState } from "react";
import {
  Check as CheckIcon,
  Edit2 as EditIcon,
  MapPin,
  Search as SearchIcon,
  Send,
  Slash,
  Trash2 as DeleteIcon,
  Smile as SmileIcon
} from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { IconButton } from "../../../components/IconButton";
import { Modal } from "../../../components/Modal";
import { PlaintiffOffer } from "../../../models/PlaintiffOffer";
import {
  deleteOffer,
  setSelectedOffer
} from "../../../redux/actions/offer.action";
import {
  getOneRequest
} from "../../../redux/actions/requests.actions";

interface Props {
  offer: PlaintiffOffer;
}

export const OffersCard: React.FC<Props> = ({ offer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  const handleOnClose = () => {
    setModal(false);
  };

  const backgroundColorToolBar = () => {
    if (offer.Deshabilitado === false && offer.Aceptada === false) {
      return "bg-primary-100";
    } else if (offer.Deshabilitado === false && offer.Aceptada === true) {
      if(isAfter(new Date(), new Date(offer.FechaTermino))){
        return "bg-purple-400"
      }else {
        return "bg-green-300";
      }
    } else if (offer.Deshabilitado === true && offer.Aceptada === false) {
      return "bg-red-400";
    }
  };
  const badgeLeftContent = () => {
    if (offer.Deshabilitado === false && offer.Aceptada === false) {
      return (
        <React.Fragment>
          <Send size={22} strokeWidth={2.5} color="white" />
        </React.Fragment>
      );
    } else if (offer.Deshabilitado === false && offer.Aceptada === true) {
      if (isAfter(new Date(), new Date(offer.FechaTermino))) {
        return (
          <React.Fragment>
            <SmileIcon size={22} strokeWidth={2.5} color="white" />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <CheckIcon size={22} strokeWidth={2.5} color="white" />
          </React.Fragment>
        );
      }
    } else if (offer.Deshabilitado === true && offer.Aceptada === false) {
      return (
        <React.Fragment>
          <Slash size={22} strokeWidth={2.5} color="white" />
        </React.Fragment>
      );
    }
  };

  const handleGoToDetails = () => {
    dispatch(setSelectedOffer(offer));
    dispatch(getOneRequest(offer.requestRef));
    navigate(`/solicitud/${offer.requestRef}/detalle/` + offer.id);
  };

  const handleGoToEdit = () => {
    dispatch(setSelectedOffer(offer));
    dispatch(getOneRequest(offer.requestRef));
    navigate("/oferente/editar/" + offer.id);
  };

  const handleDelete = () => {
    dispatch(deleteOffer(offer, offer.requestRef));
    setModal(false)
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={
        "h-96 bg-white shadow-md rounded-xl overflow-hidden hover:shadow-xl"
      }
      onMouseEnter={() => {}}
      onMouseLeave={() => {}}
    >
      <div className="w-full h-1/2 relative">
        {
          <img
            src={
              //preguntar sobre las imagenes
              offer.solicitudData?.Imagenes && offer.solicitudData.Imagenes[0]
                ? offer.solicitudData.Imagenes[0].URL
                : "/assets/img/sin_imagen.jpg"
            }
            alt={`imagen-portada-solicitud-${offer.solicitudData?.Titulo}`}
            className="w-full h-full object-cover bg-gray-300"
          />
        }
        <div className="absolute top-0 left-0 right-0 bottom-0">
          <div
            className={clsx(
              "absolute left-5 top-5 h-12 w-12 rounded-full overflow-hidden bg-white"
            )}
          >
            <div
              className={clsx(
                "flex flex-col items-center justify-center text-white w-full h-full",
                backgroundColorToolBar()
              )}
            >
              {badgeLeftContent()}
            </div>
          </div>
          {offer.Deshabilitado === false && offer.Aceptada === false && (
            <div className="absolute bottom-4 left-0 right-0 flex items-center justify-center text-center">
              <span
                className={clsx(
                  "rounded-full w-2/4 py-1 bg-primary text-sm font-semibold text-white"
                )}
              >
                Oferta Activa
              </span>
            </div>
          )}
          {offer.Deshabilitado === false && offer.Aceptada === true && isAfter(new Date(), new Date(offer.FechaTermino)) === true && (
            <div className="absolute bottom-4 left-0 right-0 flex items-center justify-center text-center">
              <span
                className={clsx(
                  "rounded-full w-2/4 py-1 bg-purple-400 text-sm font-semibold text-white"
                )}
              >
                Calificar
              </span>
            </div>
          )}
          {offer.Deshabilitado === false && offer.Aceptada === true && isAfter(new Date(), new Date(offer.FechaTermino)) === false && (
            <div className="absolute bottom-4 left-0 right-0 flex items-center justify-center text-center">
              <span
                className={clsx(
                  "rounded-full w-2/4 py-1 bg-green-300 text-sm font-semibold text-white"
                )}
              >
                Oferta Aceptada
              </span>
            </div>
          )}
          {offer.Deshabilitado === true && offer.Aceptada === false && (
            <div className="absolute bottom-4 left-0 right-0 flex items-center justify-center text-center">
              <span
                className={clsx(
                  "rounded-full w-2/4 py-1 bg-red-400 text-sm font-semibold text-white"
                )}
              >
                Oferta no Aceptada
              </span>
            </div>
          )}
        </div>
      </div>
      {/* 
        Seccion inferior de la Tarjeta
      */}
      <div className="w-full h-1/2 flex flex-col justify-between">
        <h6 className="text-lg text-secondary font-bold mt-2 mx-4 ">
          {offer.solicitudData?.Titulo}
        </h6>
        <div className="mx-4">
          <p className="font-semibold text-gray-400">
            <span className="text-secondary">
              ${new Intl.NumberFormat("es-ES").format(offer.solicitudData!.Precio)}
            </span>{" "}
            ({offer.solicitudData?.Categoria})
          </p>
          <p className="text-sm text-gray-400 flex">
            <span>
              <MapPin
                size={13}
                className="text-secondary mr-1 mt-1"
                strokeWidth={2.5}
              />
            </span>
            {offer.solicitudData?.Distancia
              ? offer.solicitudData?.Distancia >= 1000
                ? `${(offer.solicitudData?.Distancia / 1000).toFixed(1)} km`
                : `${offer.solicitudData?.Distancia} m`
              : ""}
            · {offer.solicitudData?.Region} · {offer.solicitudData?.Comuna}
          </p>
        </div>
        <div
          className={clsx(
            backgroundColorToolBar(),
            "h-12 w-full flex justify-around items-center"
          )}
        >
          <IconButton onClick={handleGoToDetails}>
            <SearchIcon
              size={20}
              className="text-secondary"
              strokeWidth={2.5}
            />
          </IconButton>
          <IconButton onClick={handleGoToEdit}>
            <EditIcon size={20} className="text-secondary" strokeWidth={2.5} />
          </IconButton>
          <IconButton onClick={openModal}>
            <DeleteIcon
              size={20}
              className="text-secondary"
              strokeWidth={2.5}
            />
          </IconButton>
        </div>
        <Modal isVisible={modal} onClose={handleOnClose} isDismiseble>
          <div className="bg-white rounded-lg h-full shadow-lg p-5 ">
            <h1 className="text-lg">¿Esta seguro de eliminar esta oferta?</h1>
            <div className="mt-5">
              <Button
                text="Aceptar"
                paddingClassname="py-2 px-10"
                className=" text-sm bg-primary-light"
                onClick={() => handleDelete()}
              />
              <Button
                text="Cancelar"
                paddingClassname="py-2 px-10"
                className="text-sm bg-redButton ml-5"
                onClick={handleOnClose}
              />
            </div>
          </div>
        </Modal>
      </div>
    </motion.div>
  );
};
