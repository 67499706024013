import { useFormik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Button } from "../../components/Button";
import { MenuItem } from "../../components/MenuItem";
import { Modal } from "../../components/Modal";
import { Select } from "../../components/Select";
import { TextField } from "../../components/TextField";
import { FormState } from "../../models/form_states";
import { getCategories } from "../../redux/actions/categories.action";
import {
  editOffer,
  editOfferInitial
} from "../../redux/actions/offer.action";
import { CategoriesState } from "../../redux/reducers/categories.reducer";
import { OfferState } from "../../redux/reducers/offer.reducer";
import { RequestsState } from "../../redux/reducers/requests.reducer";
import { RootState } from "../../redux/reducers/root.reducer";

export const EditOffer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCategories());
    // eslint-disable-next-line
  }, []);

  const { categories } = useSelector<RootState, CategoriesState>(
    (state) => state.categoriesReducer
  );
  const { selected: selectedRequest } = useSelector<RootState, RequestsState>(
    (state) => state.requestReducer
  );

  const {
    selected: selectedOffer,
    edit: { state },
  } = useSelector<RootState, OfferState>((state) => state.offerReducer);

  const { handleSubmit, values, handleChange, touched, setFieldValue, errors } =
    useFormik({
      initialValues: {
        Titulo: selectedOffer?.Titulo ? selectedOffer.Titulo : "",
        Descripcion: selectedOffer?.Descripcion
          ? selectedOffer.Descripcion
          : "",
        //Categoria: selectedOffer?.Categoria ? selectedOffer.Categoria : "",
        Precio: selectedOffer?.Precio ? selectedOffer.Precio : "",
        Cantidad: {
          Tipo: selectedOffer?.Cantidad?.Tipo
            ? selectedOffer.Cantidad.Tipo
            : "",
          Cantidad: selectedOffer?.Cantidad?.Cantidad
            ? selectedOffer.Cantidad.Cantidad
            : "",
        },
        FechaInicio: selectedOffer?.FechaInicio
          ? selectedOffer.FechaInicio
          : "",
        FechaTermino: selectedOffer?.FechaTermino
          ? selectedOffer.FechaTermino
          : "",
        Mensaje: selectedOffer?.Mensaje ? selectedOffer.Mensaje : "",
      },
      onSubmit: (values) => {
        dispatch(
          editOffer(
            {
              ...values,
              id: selectedOffer.id,
              userId: selectedOffer.Usuario.Id,
            },
            selectedRequest!.id
          )
        );
      },
      validationSchema: yup.object({
        Titulo: yup.string().required("Debe ingresar titulo"),
        Descripcion: yup.string().required("Debe ingresar descripción"),
        Precio: yup.number().required("Debe ingregsar presupuesto"),
        Cantidad: yup.object({
          Tipo: yup.string().required("Debe ingresar tipo de unidad"),
          Cantidad: yup
            .number()
            .min(0, "La cantidad debe ser mayor o igual a 0")
            .required("Debe ingresar unidades"),
        }),
        FechaInicio: yup.string().required("Debe ingresar fecha inicio"),
        FechaTermino: yup.string().required("Debe ingresar fecha termino"),
        Mensaje: yup.string().required("Debe Ingresar un mensaje"),
      }),
    });

  const [modal, setModal] = useState({
    open: false,
    text: "Oferta editada correctamente",
  });

  const openModal = (text: string) => {
    setModal({ open: true, text });
  };

  const handleCloseModal = () => {
    setModal({ open: false, text: "" });
    setTimeout(() => {
      navigate("/oferente/ofertasRealizadas", { replace: true });
      dispatch(editOfferInitial());
    }, 50);
  };

  useEffect(() => {
    if (state === FormState.Success) {
      openModal("Oferta editada correctamente");
    }
  }, [state]);

  const handleCloseEdit = () => {
    navigate("/oferente/ofertasRealizadas");
  };
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="py-10 px-8 lg:container lg:mx-auto"
    >
      <Helmet title="Editar Oferta" />
      <h1 className=" text-3xl font-bold text-deepBlue mb-5">Editar Oferta</h1>
      <motion.div>
        <form
          className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full"
          onSubmit={handleSubmit}
        >
          <div className="">
            <TextField
              label="Titulo"
              placeholder="Titulo"
              id="Titulo"
              divClassName="mb-4"
              value={values.Titulo}
              onChange={handleChange}
              helperText={touched.Titulo && errors.Titulo}
              error={touched.Titulo && Boolean(errors.Titulo)}
            />
            {/* Cambiar por un autocomplete */}

            <TextField
              label="Descripcion"
              placeholder="Descripcion"
              id="Descripcion"
              divClassName="mb-4"
              autoComplete="off"
              multiline
              value={values.Descripcion}
              onChange={handleChange}
              helperText={touched.Descripcion && errors.Descripcion}
              error={touched.Descripcion && Boolean(errors.Descripcion)}
            />
            {/* <Select
              id="Categoria"
              fullWidth
              label="Categoria"
              placeholder="Seleccionar Categoria"
              value={values.Categoria}
              helperText={touched.Categoria && errors.Categoria}
              error={touched.Categoria && Boolean(errors.Categoria)}
              divClassName="mb-4"
              onChange={(value) => {
                setFieldValue("Categoria", value);
              }}
            >
              {categories.map((category, index) => {
                return (
                  <MenuItem
                    value={category.Nombre}
                    label={category.Nombre}
                    key={`category-${index}`}
                  />
                );
              })}
            </Select> */}
            <TextField
              label="Presupuesto"
              placeholder="Precio"
              id="Precio"
              divClassName="mb-4"
              value={values.Precio}
              onChange={handleChange}
              helperText={touched.Precio && errors.Precio}
              error={touched.Precio && Boolean(errors.Precio)}
              type="number"
            />
          </div>
          <div className="">
            <TextField
              label="Fecha inicio"
              id="FechaInicio"
              type="date"
              divClassName="mb-4"
              value={values.FechaInicio}
              onChange={handleChange}
              helperText={touched.FechaInicio && errors.FechaInicio}
              error={touched.FechaInicio && Boolean(errors.FechaInicio)}
            />
            <TextField
              label="Fecha termino"
              id="FechaTermino"
              type="date"
              divClassName="mb-4"
              value={values.FechaTermino}
              onChange={handleChange}
              helperText={touched.FechaTermino && errors.FechaTermino}
              error={touched.FechaTermino && Boolean(errors.FechaTermino)}
            />
            <div className="mb-10">
              <div className="mt-4">
                <div className="grid grid-cols-2 gap-4">
                  <Select
                    id="Cantidad.Tipo"
                    label="Cantidad"
                    placeholder="Seleccionar Cantidad"
                    value={values.Cantidad.Tipo}
                    helperText={
                      touched?.Cantidad?.Tipo && errors?.Cantidad?.Tipo
                    }
                    error={
                      touched?.Cantidad?.Tipo && Boolean(errors?.Cantidad?.Tipo)
                    }
                    onChange={(value) => {
                      setFieldValue("Cantidad.Tipo", value);
                    }}
                  >
                    {[
                      "milímetro",
                      "centímetro",
                      "pulgada",
                      "metro",
                      "kilómetro",
                      "metro cuadrado",
                      "hectárea",
                      "kilómetro cuadrado",
                      "centímetro cúbico",
                      "metro cúbico",
                      "mililitro",
                      "litro",
                      "gramo",
                      "kilogramo",
                      "tonelada",
                      "minuto",
                      "hora",
                      "kilovatio-hora",
                      "día",
                      "unidad",
                      "mes",
                      "año",
                    ].map((Unidad, index) => (
                      <MenuItem
                        key={`Unidad-${index}`}
                        value={Unidad}
                        label={Unidad}
                      />
                    ))}
                  </Select>
                  <TextField
                    label="Cantidad"
                    placeholder="Cantidad"
                    id="Cantidad.Cantidad"
                    value={values.Cantidad.Cantidad}
                    onChange={handleChange}
                    helperText={
                      touched?.Cantidad?.Cantidad && errors?.Cantidad?.Cantidad
                    }
                    error={
                      touched?.Cantidad?.Cantidad &&
                      Boolean(errors?.Cantidad?.Cantidad)
                    }
                    type="number"
                  />
                </div>
                <TextField
                  label="Mensaje"
                  placeholder="mensaje"
                  id="Mensaje"
                  multiline
                  divClassName="mt-4"
                  value={values.Mensaje}
                  onChange={handleChange}
                  helperText={touched.Mensaje && errors.Mensaje}
                  error={touched.Mensaje && Boolean(errors.Mensaje)}
                />
              </div>
            </div>

            <div className="md:flex justify-center md:flex justify-start" >
              <Button
                type="submit"
                paddingClassname="p-4"
                text="Modificar Oferta"
                color="secondary"
                className="mt-2  md:ml-4 w-56"
                isLoading={state === FormState.Submitting}
                disabled={state === FormState.Submitting}
              />
              <Button
                type="reset"
                paddingClassname="p-4"
                text="Cancelar Cambios"
                onClick={handleCloseEdit}
                className="bg-redButton mt-2 md:mt-2 md:ml-1 w-56 "
                disabled={state === FormState.Submitting}
              />
            </div>
          </div>
        </form>
      </motion.div>
      <Modal isVisible={modal.open} onClose={handleCloseModal}>
        <div className="bg-white w-full rounded-lg text-center p-4">
          <p className="text-sm font-semibold">{modal.text}</p>
          <Button
            text={"Ok"}
            className="mt-3 bg-primary-light font-semibold text-white"
            paddingClassname="py-1 px-3"
            onClick={handleCloseModal}
          />
        </div>
      </Modal>
    </motion.main>
  );
};
