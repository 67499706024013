import React from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import Routes from "./router/Routes";
import "./theme/index.css";

function App() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet titleTemplate="%s | DemandApp" defaultTitle="DemandApp" />
        <Routes />
      </HelmetProvider>
    </React.Fragment>
  );
}

export default App;
