import React, { useEffect, useMemo } from "react";
import { Button } from "../components/Button";
import imagenMapa from "../assets/img/imagen_mapa.png";
import { ChevronRight as ChevronRightIcon } from "react-feather";
import AceptaOfertaIcon from "../assets/icons/AceptaOfertaIcon";
import ConfirmaServicioIcon from "../assets/icons/ConfirmaServicioIcon";
import PublicaAvisoIcon from "../assets/icons/PublicaAvisoIcon";
import RecibeOfertasIcon from "../assets/icons/RecibeOfertasIcon";
import ValorasIcon from "../assets/icons/ValorasIcon";
import ContactIcon from "../assets/icons/ContactIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCountRequestsCategories } from "../redux/actions/categories.action";
import { RootState } from "../redux/reducers/root.reducer";
import { CategoriesState } from "../redux/reducers/categories.reducer";
import { Categories } from "../models/Categories";

export const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountRequestsCategories());
  }, []);

  const { categoriesCountRequest: categories } = useSelector<
    RootState,
    CategoriesState
  >((state) => state.categoriesReducer);

  const howItsWork = useMemo(
    () => [
      { name: "Publica tu aviso", Icon: PublicaAvisoIcon },
      { name: "Recibe ofertas", Icon: RecibeOfertasIcon },
      { name: "Aceptas la oferta", Icon: AceptaOfertaIcon },
      { name: "Contactás al oferente", Icon: ContactIcon},
      { name: "Confirmas el servicio", Icon: ConfirmaServicioIcon },
      { name: "Valoras", Icon: ValorasIcon },
    ],
    []
  );

  const navigate = useNavigate();

  const requestNear = () => {
    navigate("/oferente/ofertasCercanas");
  };

  return (
    <main className="pb-10">
      <div
        className="object-cover bg-main-image bg-cover w-full items-center flex justify-center md:flex-row flex-col"
        style={{
          height: "500px",
          backgroundPositionX: "center",
        }}
      >
        <Button
          text="¿Necesitas un servicio?"
          className="sm:mr-3 mb-3 md:mb-0"
          color="secondary"
          style={{ minWidth: 230 }}
          onClick={() => {
            navigate("/auth/regitroDemandante");
          }}
        />
        <Button
          color="primary"
          text="¿Ofreces servicios?"
          style={{ minWidth: 230 }}
          onClick={() => navigate("/solicitudesActivas")}
        />
      </div>
      <div className="sm:px-8 px-4">
        <div className="py-7">
          <h2 className="text-deepBlue text-2xl font-bold mb-6">
            Ver la demanda de servicios
          </h2>
          <div className="flex flex-col w-full sm:flex-row">
            <div className="flex flex-wrap w-full sm:w-4/6 overflow-x-scroll">
              {categories?.map((categorie, index) => {
                return (
                  <ServicesCard
                    categorie={categorie}
                    key={`Service-Card-${index}`}
                  />
                );
              })}
            </div>
            <div className="sm:w-2/6 ">
              <MapCard />
            </div>
          </div>
        </div>
        <div className="flex flex-col rounded-xl overflow-hidden mb-10 sm:flex-row">
          <div className="sm:w-2/5 w-full bg-secondary text-white flex flex-col items-center justify-center p-6">
            <div className="">
              <h4 className="text-2xl font-bold mb-2">Solicita tu servicio</h4>
              <h4 className="text-xl font-semibold mb-2">
                Ahorra tiempo, dinero y elige calidad
              </h4>
              <Button
                text="Regístrate Aquí"
                className="mb-4 mt-1 bg-button"
                insideClassName="py-2 px-10"
                onClick={() => {
                  navigate("/auth/regitroDemandante");
                }}
              />
            </div>
          </div>
          <div className="bg-service h-40 sm:h-72 sm:w-3/5 bg-cover bg-center" />
        </div>
        <div>
          <h4 className="text-deepBlue text-2xl font-bold mb-6">
            Cómo Funciona
          </h4>
          <div className="flex flex-wrap">
            {howItsWork.map((element, index) => {
              return (
                <div
                  className="flex flex-col w-1/2 mb-5 items-center sm:mb-0 sm:flex-1 sm:w-auto"
                  key={`how-it-work-${index}`}
                >
                  {element.Icon ? <element.Icon height={72} width={72} /> : ""}
                  <p className="text-md mt-2 text-deepBlue font-bold text-center">
                    {element.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </main>
  );
};

const MapCard = () => {
  const navigate = useNavigate();

  return (
    <div
      className="h-36 flex rounded-xl overflow-hidden"
      onClick={() => navigate("/oferente/ofertasCercanas")}
      style={{ cursor: "pointer" }}
    >
      <img
        src={imagenMapa}
        alt="imagen-mapa"
        className="object-cover w-5/12 bg-gray-300"
      />
      <div className="text-white bg-primary w-7/12 flex items-center pl-5">
        <div>
          <h5 className="font-bold text-lg">!Explora cerca de ti¡</h5>
          <p className="text-sm">100 Publicaciones</p>
        </div>
        <ChevronRightIcon size={42} />
      </div>
    </div>
  );
};

interface ServiceProps {
  categorie: Categories;
}

const ServicesCard: React.FC<ServiceProps> = ({ categorie }) => {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate("/solicitudesActivas")}
    style={{ cursor: "pointer" }} className="flex mb-4 items-center sm:w-48">
      <div
        className="bg-deepBlue w-14 h-14 rounded-lg flex justify-center items-center mr-3"
        
      >
        <span className="text-white text-2xl">
          {categorie.Nombre.slice(0, 2).toUpperCase()}
        </span>
      </div>
      <div className="flex flex-col justify-center">
        <h6 className="text-deepBlue text-md font-bold">{categorie.Nombre}</h6>
        <p className="text-gray-400 text-sm">
          {categorie.CantidadSolicitudes ? categorie.CantidadSolicitudes : 0}{" "}
          solicitudes
        </p>
      </div>
    </div>
  );
};
