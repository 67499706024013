import { useJsApiLoader,  } from "@react-google-maps/api";
import clsx from "clsx";
import React, { CSSProperties, useEffect, useRef } from "react";
import { LIBRARIES, MAPS_API_KEY } from "../configs/constants";

interface Props {
  helperText?: any;
  placeholder?: string;
  helperTextColor?: string;
  helperTextClassName?: string;
  divClassName?: string;
  divStyle?: CSSProperties;
  value?: string;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  outlined?: boolean;
  isLoading?: boolean;
  className?: string;
  id?: string;
  onChange?: (place: google.maps.places.PlaceResult) => void;
}

export function AutocompletePlaces({
  helperText,
  helperTextClassName,
  divStyle,
  divClassName,
  className,
  label,
  id,
  value,
  error,
  disabled,
  outlined = true,
  onChange,
}: Props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAPS_API_KEY,
    language: "es",
    libraries: LIBRARIES,
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleOusideClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (isLoaded) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current!
      );
      autocomplete.setOptions({
        componentRestrictions: { country: "CL" },
      });
      autocomplete.addListener("place_changed", () => {
        if (onChange) {
          onChange(autocomplete.getPlace());
        }
      });
    }
    // eslint-disable-next-line
  }, [isLoaded]);

  return (
    <div className={divClassName} style={divStyle}>
      {label && (
        <label
          className="pl-4 pb-2 font-semibold text-deepBlue text-lg "
          htmlFor={id ? id : `input-${label}`}
        >
          {label}
        </label>
      )}
      <div
        className={clsx("rounded-full bg-white h-12 relative flex cursor-text")}
        onClick={handleOusideClick}
      >
        <input
          id={id ? id : `input-${label}`}
          ref={inputRef}
          disabled={disabled}
          defaultValue={value}
          className={clsx(
            `w-full px-4 rounded-full h-full focus:border-secondary hover:border-secondary hover:bg-secondary-light hover:bg-opacity-10 `,
            className,
            outlined
              ? "border-solid border-1.5 border-primary outline-none"
              : "border-none border-0 outline-none"
          )}
          type="text"
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />
      </div>
      {helperText && error && (
        <span
          className={clsx("ml-5 font-semibold", helperTextClassName, error && "text-red-500")}
          style={{ color: helperText }}
        >
          {helperText}
        </span>
      )}
    </div>
  );
}
