import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "../redux/actions/auth.actions";
import { Button } from "./Button";
import {
  Home as HomeIcon,
  X as CloseIcon,
  Archive as ArchiveIcon,
  CreditCard as CardIcon,
  Icon,
} from "react-feather";

interface MenuItem {
  label: string;
  action: VoidFunction;
  Icon: Icon;
}

interface Props {
  onClose: VoidFunction;
}

export const PlaintiffMenu: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        label: "Inicio",
        action: () => {
          navigate("/demandante/solicitudesRealizadas");
        },
        Icon: HomeIcon,
      },
      {
        label: "Mis Solicitudes",
        action: () => {
          navigate("/demandante/solicitudesRealizadas");
        },
        Icon: ArchiveIcon,
      },
      {
        label: "Mis Transacciones",
        action: () => {
          navigate("/demandante/transacciones");
        },
        Icon: CardIcon,
      },
      {
        label: "Cerrar Sesión",
        action: () => {
          dispatch(signOut());
          navigate("/inicio");
        },
        Icon: CloseIcon,
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Button
        text={"Mi Cuenta"}
        color="secondary"
        insideClassName="py-1 px-5"
        className="mb-4 mt-1"
        onClick={() => {
          navigate("/demandante/adminCuenta");
          onClose();
        }}
      />
      <ul className="list-none w-full">
        {menuItems.map((item, index) => (
          <li
            key={`plaintiff-menu-${index}`}
            className="h-10 flex pointer hover:bg-gray-500 hover:bg-opacity-20 w-full px-4 items-center cursor-pointer"
            onClick={() => {
              item.action();
              onClose();
            }}
          >
            <item.Icon size={26} color="white" strokeWidth={1.5} />
            <span className="text-sm text-white font-semibold ml-5">
              {item.label}
            </span>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};
