import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut as signOutFirebase,
  updateProfile,
} from "@firebase/auth";
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import {
  getAuth,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { updateDoc, onSnapshot } from "firebase/firestore";
import { auth, firestore, storage } from "../../configs/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { authErrorCodes } from "../../models/authErrorCodes";
import { Docs } from "../../models/PlaintiffOffer";
import * as types from "../types";
import { isOffered } from "./requests.actions";

interface Credentials {
  Email: string;
  Password: string;
}

export function signIn(credentials: Credentials): AppThunk {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });
    try {
      const response = await signInWithEmailAndPassword(
        auth,
        credentials.Email,
        credentials.Password
      );
      dispatch({
        type: types.AUTH_SIGN_IN_SUCCESS,
        payload: response.user,
      });
    } catch (error: any) {
      let message = "La cuenta especificada no existe";
      if (error.name === "FirebaseError") {
        if (
          error.code === authErrorCodes.INVALID_PASSWORD ||
          error.code === authErrorCodes.INVALID_EMAIL
        ) {
          message = "Correo o contraseña no válidos";
        }
        if (error.code === authErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
          message =
            "Demasiados intentos, intente más tarde o resetee la contraseña";
        }
      }
      dispatch({ type: types.AUTH_SIGN_IN_FAILURE, payload: message });
    }
  };
}

export function signInFromRequest(
  credentials: Credentials,
  requestId: string
): AppThunk {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });
    try {
      const response = await signInWithEmailAndPassword(
        auth,
        credentials.Email,
        credentials.Password
      );
      const usersRef = collection(firestore, "Usuarios");
      const q = query(usersRef, where("Email", "==", response.user.email));
      const userResponse = await getDocs(q);
      const userDB: any = {
        ...userResponse.docs[0].data(),
        id: userResponse.docs[0].id,
      };
      dispatch({
        type: types.AUTH_SIGN_IN_SUCCESS,
        payload: response.user,
      });
      setTimeout(() => {
        dispatch(isOffered(requestId, userDB));
      }, 100);
    } catch (error: any) {
      let message = "La cuenta especificada no existe";
      if (error.name === "FirebaseError") {
        if (
          error.code === authErrorCodes.INVALID_PASSWORD ||
          error.code === authErrorCodes.INVALID_EMAIL
        ) {
          message = "Correo o contraseña no válidos";
        }
        if (error.code === authErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
          message =
            "Demasiados intentos, intente más tarde o resetee la contraseña";
        }
      }
      dispatch({ type: types.AUTH_SIGN_IN_FAILURE, payload: message });
    }
  };
}

let unsubscriber: undefined | VoidFunction;

export function validateSession(): AppThunk {
  return (dispatch) => {
    dispatch({ type: types.AUTH_STATE_REQUEST });
    onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          const token = await user.getIdToken(false);
          const usersRef = collection(firestore, "Usuarios");
          const q = query(usersRef, where("Email", "==", user.email));
          // const userResponse = await getDocs(q);
          // const userDB: any = {
          //   ...userResponse.docs[0]?.data(),
          //   id: userResponse.docs[0]?.id,
          // };

          unsubscriber = onSnapshot(q, (snap) => {
            const doc = snap.docs[0];
            const data = doc.data();
            const user = { ...data, id: doc.id };

            if (snap.empty) throw Error("El usuario no existe.")

            if (data.Bloqueado === true)
              // TODO: Mandar alerta de deshabilitado
              dispatch({
                type: types.AUTH_SIGN_OUT,
              });
            else
              dispatch({
                type: types.AUTH_STATE_SUCCESS,
                payload: {
                  ...user,
                  Token: token,
                },
              });
          });
        } else {
          dispatch({
            type: types.AUTH_SIGN_OUT,
          });
        }
      },
      (error) => {
        dispatch({
          type: types.AUTH_STATE_FAILURE,
          error: error,
        });
      }
    );
  };
}

interface OffererCredentials {
  Nombre: string;
  Apellido: string;
  Region: string;
  FechaNacimiento: string;
  Comuna: string;
  Email: string;
  Contrasena: string;
  RepetirContrasena: string;
  Direccion: string;
  Telefono: string;
  CategoriaServicios: string;
  Archivos: File[];
  Premium: boolean;
  TerminosYCondiciones: boolean;
}

export function registerOfferer(credentials: OffererCredentials): AppThunk {
  return async (dispatch) => {
    dispatch({ type: types.REGISTER_OFFERER_SUBMITTING });

    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        credentials.Email,
        credentials.Contrasena
      );

      const user = response.user;

      const docRef = doc(firestore, "Usuarios", user.uid);

      await updateProfile(response.user, {
        displayName: credentials.Nombre + " " + credentials.Apellido,
      });

      const dataToDB: any = {
        ...credentials,
        FechaCreacion: serverTimestamp(),
        FechaActualizacion: serverTimestamp(),
        Calificacion: 5,
        FechaPremium: null,
        TipoUsuario: "Oferente",
        Estado: "Activo",
      };
      delete dataToDB.Contrasena;
      delete dataToDB.RepetirContrasena;
      delete dataToDB.Archivos
      await setDoc(docRef, dataToDB);

      const urls: Docs[] = [];
      for (let i = 0; i < credentials.Archivos.length; i++) {
        const file = credentials.Archivos[i];

        const storageRef = ref(
          storage,
          `${user.uid}/files/${file.name.slice(0, 30).replace(" ", "_")}}`
        );

        const uploadTask = await uploadBytes(storageRef, file);
        const publicUrl = await getDownloadURL(uploadTask.ref);

        urls.push({
          URL: publicUrl,
          URI: storageRef.fullPath,
        });
      }

      const updateFileData = {
        Archivos: urls,
      }

      await updateDoc(docRef, updateFileData);
      

      dispatch({
        type: types.REGISTER_OFFERER_SUCCESS,
      });
    } catch (error: any) {
      let message = "Error Inesperado, intentelo nuevamente";
      if (error.name === "FirebaseError") {
        if (error.code === authErrorCodes.EMAIL_EXISTS) {
          message = "email exists";
        }
      }
      dispatch({
        type: types.REGISTER_OFFERER_FAILURE,
        payload: message,
      });
    }
  };
}

interface PlaintiffCredentials {
  Nombre: string;
  Apellido: string;
  Region: string;
  FechaNacimiento: string;
  Comuna: string;
  Email: string;
  Contrasena: string;
  RepetirContrasena: string;
  Direccion: string;
  Telefono: string;
  TerminosYCondiciones: boolean;
}

export function registerPlaintiff(credentials: PlaintiffCredentials): AppThunk {
  return async (dispatch) => {
    dispatch({ type: types.REGISTER_PLAINTIFF_SUBMITTING });

    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        credentials.Email,
        credentials.Contrasena
      );

      const user = response.user;

      await updateProfile(response.user, {
        displayName: credentials.Nombre + " " + credentials.Apellido,
      });

      const dataToDB: any = {
        ...credentials,
        Estado: "Activo",
        FechaCreacion: serverTimestamp(),
        FechaActualizacion: serverTimestamp(),
        Calificacion: 5,
        TipoUsuario: "Demandante",
      };

      delete dataToDB.Contrasena;
      delete dataToDB.RepetirContrasena;
      delete dataToDB.Archivos;

      await setDoc(doc(firestore, "Usuarios", user.uid), dataToDB);
      dispatch({ type: types.REGISTER_PLAINTIFF_SUCCESS });
    } catch (error: any) {
      console.log(error);
      let message = "Error Inesperado, intentelo nuevamente";
      if (error.name === "FirebaseError") {
        if (error.code === authErrorCodes.EMAIL_EXISTS) {
          message = "email exists";
        }
      }
      dispatch({
        type: types.REGISTER_PLAINTIFF_FAILURE,
        payload: message,
      });
    }
  };
}
export const updatePlantiff = (values: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.REGISTER_UPDATE_ONE_SUBMMITING,
    });
    try {
      const { user } = getState().authReducer;

      const plaintiffInfo = doc(firestore, "Usuarios", user.id);
      // eslint-disable-next-line
      const response = await updateDoc(plaintiffInfo, {
        FechaActualizacion: serverTimestamp(),
        Nombre: values.Nombre,
        Direccion: values.Direccion,
        Apellido: values.Apellido,
        Comuna: values.Comuna,
        Region: values.Region,
        FechaNacimiento: values.FechaNacimiento,
      });
      dispatch({
        type: types.REGISTER_UPDATE_ONE_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.REGISTER_UPDATE_ONE_FAILURE,
        payload: "Error inesperado",
      });
    }
  };
};

export const updateOfferer = (values: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.REGISTER_UPDATE_ONE_SUBMMITING,
    });
    try {
      const { user } = getState().authReducer;

      const userRef = doc(firestore, "Usuarios", user.id);
      // eslint-disable-next-line
      const response = await updateDoc(userRef, {
        FechaActualizacion: serverTimestamp(),
        Nombre: values.Nombre,
        Direccion: values.Direccion,
        Apellido: values.Apellido,
        Comuna: values.Comuna,
        Region: values.Region,
        FechaNacimiento: values.FechaNacimiento,
      });

      const filesUrls: Docs[] = [];
      if (values.Archivos && Array.isArray(values.Archivos)) {
        for (const file of values.Archivos) {
          if (typeof file === "object") {
            if (file instanceof File) {
              const storageRef = ref(
                storage,
                `${user.id}/files/${file.name.slice(0, 30).replace(" ", "_")}`
              );
              const uploadTask = await uploadBytes(storageRef, file);
              const publicUrl = await getDownloadURL(uploadTask.ref);

              filesUrls.push({
                URL: publicUrl,
                URI: storageRef.fullPath,
              });
            } else {
              filesUrls.push(file);
            }
          }
        }
      }

      const updateFileData: any = {};

      if (filesUrls.length > 0) {
        updateFileData.Archivos = filesUrls;
      }

      updateDoc(userRef, updateFileData);
      dispatch({
        type: types.REGISTER_UPDATE_ONE_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.REGISTER_UPDATE_ONE_FAILURE,
        payload: "Error inesperado",
      });
    }
  };
};
export const updateEmailPlantiff = (values: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.REGISTER_UPDATE_EMAIL_SUBMMITING,
    });
    try {
      const { user } = getState().authReducer;
      const auth = getAuth();
      if (auth.currentUser) {
        await auth.currentUser.getIdToken(true);
        // eslint-disable-next-line
        const responseEmail = await updateEmail(auth.currentUser, values);
        const UpdateEmail = doc(firestore, "Usuarios", user.id);
        // eslint-disable-next-line
        const response = await updateDoc(UpdateEmail, {
          Email: values,
        });
        dispatch({
          type: types.REGISTER_UPDATE_EMAIL_SUCCESS,
        });
      } else {
        throw new Error("Error Usuario no registrado.");
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.REGISTER_UPDATE_EMAIL_FAILURE,
        payload: "Error inesperado",
      });
    }
  };
};

export const resetContraseña = (credentials: Credentials): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_RESET_PASSWORD_REQUEST,
    });
    try {
      const auth = getAuth();

      await sendPasswordResetEmail(auth, credentials.Email).then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
        });
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.AUTH_RESET_PASSWORD_FAILURE,
        payload: "Error inesperado",
      });
    }
  };
};

export const resetPasswordInitial = (): Action => ({
  type: types.AUTH_RESET_PASSWORD_INITIAL,
});
export const updateContraseña = (
  newPassword: string,
  oldPasswod: string
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.REGISTER_UPDATE_PASSWORD_SUBMMITING,
    });
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const emailCredentials = EmailAuthProvider.credential(
          user.email!,
          oldPasswod
        );
        await reauthenticateWithCredential(user, emailCredentials);
        await user.getIdToken(true);
        await updatePassword(user, newPassword);
      } else {
        throw new Error("Ha ocurrido un error al cambiar la contraseña.");
      }
      dispatch({
        type: types.REGISTER_UPDATE_PASSWORD_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.REGISTER_UPDATE_PASSWORD_FAILURE,
        payload: "Error inesperado",
      });
    }
  };
};
export function signOut(): AppThunk {
  return async () => {
    await signOutFirebase(auth);
    localStorage.removeItem("lastPath");
  };
}

export function resetPassword(credentials: Credentials): AppThunk {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });
  };
}
/* function openSnack(arg0: string, SUCCESS: any): any {
  throw new Error("Function not implemented.");
} */

export const setSelectedUser = (user: any): Action => ({
  type: types.USERS_SET_SELECTED,
  payload: user,
});
