import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Button } from "../components/Button";
import { TextField } from "../components/TextField";
import { FormState } from "../models/form_state";
import { updateContraseña } from "../redux/actions/auth.actions";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { ModalMessage } from "./ModalMessage";
import { ModalMessageError } from "./ModalMessageError";

interface Props {
  helperText?: any;
  placeholder?: string;
  icon?: React.ReactNode;
  helperTextColor?: string;
  helperTextClassName?: string;
  divClassName?: string;
  label?: string;
  error?: boolean;
  outlined?: boolean;
  multiline?: boolean;
}

export const EditPassword: React.FC<Props> = ({
  helperText,
  helperTextColor,
  icon,
  helperTextClassName,
  divClassName,
  label,
  error,
  outlined = true,
  multiline = false,
  ...others
}) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [showModalPassword, setShowModalPassword] = React.useState(false);
  const [showModalErrorPassword, setShowModalErrorPassword] =
    React.useState(false);
  const { handleSubmit, values, handleChange, touched, errors, resetForm } =
    useFormik({
      initialValues: {
        Contraseña: "",
        ContraseñaAnterior: "",
        ConfirmarContraseña: "",
      },
      onSubmit: (values) => {
        dispatch(
          updateContraseña(values.Contraseña, values.ContraseñaAnterior)
        );
      },
      validationSchema: yup.object({
        Contraseña: yup
          .string()
          .min(6, "Contrseña demasiado corta")
          .required("Contraseña es requerida"),
        ConfirmarContraseña: yup
          .string()
          .min(6, "Contrseña demasiado corta")
          .required()
          .oneOf(
            [yup.ref("Contraseña"), null],
            "Las contraseñas deben ser iguales"
          ),
      }),
    });
  const { edit } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );
  useEffect(() => {
    if (edit.passwordState === FormState.Success) {
      setShowModalPassword(true);
      setEditable(false);
      resetForm();
      setTimeout(() => {
        setShowModalPassword(false);
      }, 1000);
    }
    if (edit.passwordState === FormState.Failure) {
      setShowModalErrorPassword(true);
      setTimeout(() => {
        setShowModalErrorPassword(false);
      }, 1000);
    }
    return () => {
      edit.passwordState = FormState.Initial;
    };
    // eslint-disable-next-line
  }, [edit.passwordState]);
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 justify-between px-6 md:px-0 pt-6 pb-6">
          <TextField
            type="password"
            disabled={!editable}
            label="Ingresa Contaseña Anterior"
            placeholder="Contaseña"
            id="ContraseñaAnterior"
            value={values.ContraseñaAnterior}
            onChange={handleChange}
            helperText={touched.ContraseñaAnterior && errors.ContraseñaAnterior}
            error={
              touched.ContraseñaAnterior && Boolean(errors.ContraseñaAnterior)
            }
            required
          />
          <TextField
            type="password"
            disabled={!editable}
            label="Ingresa Nueva Contaseña"
            placeholder="Contaseña"
            id="Contraseña"
            value={values.Contraseña}
            onChange={handleChange}
            helperText={touched.Contraseña && errors.Contraseña}
            error={touched.Contraseña && Boolean(errors.Contraseña)}
            required
          />
          <TextField
            type="password"
            disabled={!editable}
            label="Confirmar Nueva Contaseña"
            placeholder="Confirmar Contaseña"
            id="ConfirmarContraseña"
            value={values.ConfirmarContraseña}
            onChange={handleChange}
            helperText={
              touched.ConfirmarContraseña && errors.ConfirmarContraseña
            }
            error={
              touched.ConfirmarContraseña && Boolean(errors.ConfirmarContraseña)
            }
            required
          />
          <div></div>
          <div>
            <div className="flex justify-start ">
              {editable === true ? (
                ""
              ) : (
                <Button
                  style={{ marginRight: 3 }}
                  onClick={() => setEditable(true)}
                  text="Editar Contraseña"
                  color="secondary"
                  className="mt-7 w-60 "
                />
              )}
              {editable === true ? (
                <>
                  <Button
                    style={{ marginRight: 3 }}
                    onClick={() => {
                      setEditable(false);
                    }}
                    text="Cancelar"
                    color="secondary"
                    className="mt-7 w-60 "
                  />
                  <Button
                    type="submit"
                    text="Guardar"
                    color="secondary"
                    className="mt-7 w-60 "
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>
      <ModalMessage
        title="Contraseña actualizada Correctamente"
        open={showModalPassword}
        onClose={() => setShowModalPassword(false)}
      />
      <ModalMessageError
        title="Error al Actualizar su contraseña."
        open={showModalErrorPassword}
        onClose={() => setShowModalErrorPassword(false)}
      />
    </React.Fragment>
  );
};
