import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";

// For routes that can only be accessed by "Oferente" type authenticated users or non-authenticated users
export const PublicRequestGuard = ({ children }: any) => {
  const auth = useSelector<RootState, AuthState>((state) => state.authReducer);
  const location = useLocation();

  localStorage.setItem("lastPath", location.pathname);

  if (auth?.user && auth?.user?.TipoUsuario !== "Oferente") {
    return <Navigate to="/" />;
  }

  return children;
};
