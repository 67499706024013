export const TABLE_LIMITS = [5, 10, 20, 30, 50];
export const TABLE_LIMIT_DEFAULT = TABLE_LIMITS[0];

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_STATE_REQUEST = "AUTH_STATE_REQUEST";
export const AUTH_STATE_SUCCESS = "AUTH_STATE_SUCCESS";
export const AUTH_STATE_FAILURE = "AUTH_STATE_FAILURE";
export const AUTH_RESET_PASSWORD_INITIAL = "AUTH_RESET_PASSWORD_INITIAL";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Solicitudes
export const REQUESTS_INITIAL = "[Requests] INITIAL";
export const REQUESTS_SUBMMITING = "[Requests] SUBMMITING";
export const REQUESTS_SUCCESS = "[Requests] SUCCESS";
export const REQUESTS_FAILURE = "[Requests] FAILURE";
export const REQUESTS_SET_TOTAL_DOCS = "[Requests] Set Total Docs";
export const REQUESTS_ADD_INITIAL = "[Requests] ADD Initial";
export const REQUESTS_ADD_SUBMMITING = "[Requests] ADD_SUBMMITING";
export const REQUESTS_ADD_SUCCESS = "[Requests] ADD_SUCCESS";
export const REQUESTS_ADD_FAILURE = "[Requests] ADD_FAILURE";
export const REQUESTS_DELETE_FAILURE = "[Requests] DELETE_FAILURE";
export const REQUESTS_DELETE_SUBMMITING = "[Requests] DELETE_SUBMMITING";
export const REQUESTS_DELETE_SUCCESS = "[Requests] DELETE_SUCCESS";
export const REQUESTS_GET_ONE_SUBMMITING = "[Requests] GET_ONE_SUBMMITING";
export const REQUESTS_GET_ONE_SUCCESS = "[Requests] GET_ONE_SUCCESS";
export const REQUESTS_GET_ONE_FAILURE = "[Requests] GET_ONE_FAILURE";
export const REQUESTS_GET_PREMIUM_SUBMMITING = "[Requests] GET_PREMIUM_SUBMMITING";
export const REQUESTS_GET_PREMIUM_SUCCESS = "[Requests] GET_PREMIUM_SUCCESS";
export const REQUESTS_GET_PREMIUM_FAILURE = "[Requests] GET_PREMIUM_FAILURE";
export const USERS_SET_SELECTED = "[User] set selected";

export const REQUESTS_SET_SELECTED = "[Requests] Set Selected";
export const REQUESTS_GET_OFFER_INITIAL = "[Requests] GET_OFFER_INITIAL";
export const REQUESTS_GET_OFFER_SUBMMITING = "[Requests] GET_OFFER_SUBMMITING";
export const REQUESTS_GET_OFFER_SUCCESS = "[Requests] GET_OFFER_SUCCESS";
export const REQUESTS_GET_OFFER_FAILURE = "[Requests] GET_OFFER_FAILURE";
export const REQUEST_ACCEPT_OFFER_INITIAL = "[Request] Accept_Offer_Initial";
export const REQUEST_ACCEPT_OFFER_SUBMITTING =
  "[Request] Accept_Offer_Submitting";
export const REQUEST_ACCEPT_OFFER_SUCCESS = "[Request] Accept_Offer_Success";
export const REQUEST_ACCEPT_OFFER_FAILURE = "[Request] Accept_Offer_Failure";
export const REQUEST_REJECT_OFFER_INITIAL = "[Request] Reject_Offer_Initial";
export const REQUEST_REJECT_OFFER_SUBMITTING =
  "[Request] Reject_Offer_Submitting";
export const REQUEST_REJECT_OFFER_SUCCESS = "[Request] Reject_Offer_Success";
export const REQUEST_REJECT_OFFER_FAILURE = "[Request] Reject_Offer_Failure";
export const REQUEST_EDIT_INITIAL = "[Request] Edit_Initial";
export const REQUEST_EDIT_SUBMITTING = "[Request] Edit_Submitting";
export const REQUEST_EDIT_SUCCESS = "[Request] Edit_Success";
export const REQUEST_EDIT_FAILURE = "[Request] Edit_Failure";
export const REQUEST_NOTIFY_INITIAL = "[Request] Notify_Initial";
export const REQUEST_NOTIFY_SUBMITTING = "[Request] Notify_Submitting";
export const REQUEST_NOTIFY_SUCCESS = "[Request] Notify_Success";
export const REQUEST_NOTIFY_FAILURE = "[Request] Notify_Failure";
//eliminar despues
export const REQUEST_OFFERED_INITIAL = "[Request] Offered_Initial";
export const REQUEST_OFFERED_SUBMITTING = "[Request] Offered_Submitting";
export const REQUEST_OFFERED_SUCCESS = "[Request] Offered_Success";
export const REQUEST_OFFERED_FAILURE = "[Request] Offered_Failure";

//Register Offerer
export const REGISTER_OFFERER_INITIAL = "[Register Offerer] Initial";
export const REGISTER_OFFERER_SUBMITTING = "[Register Offerer] Submitting";
export const REGISTER_OFFERER_SUCCESS = "[Register Offerer] Success";
export const REGISTER_OFFERER_FAILURE = "[Register Offerer] Failure";

// plaintiff register
export const REGISTER_PLAINTIFF_INITIAL = "[Register Plaintiff] Initial";
export const REGISTER_PLAINTIFF_SUBMITTING = "[Register Plaintiff] Submitting";
export const REGISTER_PLAINTIFF_SUCCESS = "[Register Plaintiff] Success";
export const REGISTER_PLAINTIFF_FAILURE = "[Register Plaintiff] Failure";
export const REGISTER_UPDATE_ONE_FAILURE =
  "[Register Plaintiff] update one failure";
export const REGISTER_UPDATE_ONE_SUCCESS =
  "[Register Plaintiff] update one success";
export const REGISTER_UPDATE_ONE_SUBMMITING =
  "[Register Plaintiff] update one submiting";
export const REGISTER_UPDATE_EMAIL_SUBMMITING =
  "[Register Plaintiff] update email submmiting";
export const REGISTER_UPDATE_EMAIL_SUCCESS =
  "[Register Plaintiff] update email success";
export const REGISTER_UPDATE_EMAIL_FAILURE =
  "[Register Plaintiff] update email failure";
export const REGISTER_UPDATE_PASSWORD_FAILURE =
  "[Register Plaintiff] update password failure";
export const REGISTER_UPDATE_PASSWORD_SUCCESS =
  "[Register Plaintiff] update password succes";
export const REGISTER_UPDATE_PASSWORD_SUBMMITING =
  "[Register Plaintiff] update password submmiting";

// Categorias
export const CATEGORIES_INITIAL = "[Categories] Initial";
export const CATEGORIES_SUBMITTING = "[Categories] Submitting";
export const CATEGORIES_SUCCESS = "[Categories] Success";
export const CATEGORIES_FAILURE = "[Categories] Failure";
export const CATEGORIES_COUNT_REQUEST_INITIAL =
  "[Categories] Get_Count_Initial";
export const CATEGORIES_COUNT_REQUEST_SUBMITTING =
  "[Categories] Get_Count_Submitting";
export const CATEGORIES_COUNT_REQUEST_SUCCESS =
  "[Categories] Get_Count_Success";
export const CATEGORIES_COUNT_REQUEST_FAILURE =
  "[Categories] Get_Count_Failure";

//Transacciones
export const TRANSACTION_ADD_INITIAL = "[Transaction] Add_Initial";
export const TRANSACTION_ADD_SUBMITTING = "[Transaction] Add_Submitting";
export const TRANSACTION_ADD_SUCCESS = "[Transaction] Add_Success";
export const TRANSACTION_ADD_FAILURE = "[Transaction] Add_Failure";
export const TRANSACTION_GET_INITIAL = "[Transaction] Get_Initial";
export const TRANSACTION_GET_SUBMITTING = "[Transaction] Get_Submitting";
export const TRANSACTION_GET_SUCCESS = "[Transaction] Get_Success";
export const TRANSACTION_GET_FAILURE = "[Transaction] Get_Failure";
export const TRANSACTION_OFFERER_GET_INITIAL =
  "[Transaction] Offerer_Get_Initial";
export const TRANSACTION_OFFERER_GET_SUBMITTING =
  "[Transaction] Offerer_Get_Submitting";
export const TRANSACTION_OFFERER_GET_SUCCESS =
  "[Transaction] Offerer_Get_Success";
export const TRANSACTION_OFFERER_GET_FAILURE =
  "[Transaction] Offerer_Get_Failure";

//Suscripciones
export const SUSCRIPTION_GET_INITIAL = "[Suscription] Get_Initial";
export const SUSCRIPTION_GET_SUBMITTING = "[Suscription] Get_Submitting";
export const SUSCRIPTION_GET_SUCCESS = "[Suscription] Get_Success";
export const SUSCRIPTION_GET_FAILURE = "[Suscription] Get_Failure";
export const SUSCRIPTION_SUSCRIBE_USER_INITIAL =
  "[Suscription] Suscribe_User_Initial";
export const SUSCRIPTION_SUSCRIBE_USER_SUBMITTING =
  "[Suscription] Suscribe_User_Submitting";
export const SUSCRIPTION_SUSCRIBE_USER_SUCCESS =
  "[Suscription] Suscribe_User_Success";
export const SUSCRIPTION_SUSCRIBE_USER_FAILURE =
  "[Suscription] Suscribe_User_Failure";

//Ofertas
export const OFFER_GET_INITIAL = "[Offer] Get_Initial";
export const OFFER_GET_SUBMITTING = "[Offer] Get_Submitting";
export const OFFER_GET_SUCCESS = "[Offer] Get_Success";
export const OFFER_GET_FAILURE = "[Offer] Get_Failure";
export const OFFER_ADD_INITIAL = "[Offer] Add_Initial";
export const OFFER_ADD_SUBMITTING = "[Offer] Add_Submitting";
export const OFFER_ADD_SUCCESS = "[Offer] Add_Success";
export const OFFER_ADD_FAILURE = "[Offer] Add_Failure";
export const OFFERS_SET_SELECTED = "[Offer] Set_Selected";
export const OFFER_GET_ONE_INITIAL = "[Offer] Get_One_Initial";
export const OFFER_GET_ONE_SUBMMITING = "[Offer] Get_One_Submitting";
export const OFFER_GET_ONE_SUCCESS = "[Offer] Get_One_Success";
export const OFFER_GET_ONE_FAILURE = "[Offer] Get_One_Failure";
export const OFFER_EDIT_INITIAL = "[Offer] Edit_Initial";
export const OFFER_EDIT_SUBMITTING = "[Offer] Edit_Submitting";
export const OFFER_EDIT_SUCCESS = "[Offer] Edit_Success";
export const OFFER_EDIT_FAILURE = "[Offer] Edit_Failure";
export const OFFER_DELETE_INITIAL = "[Offer] Delete_Initial";
export const OFFER_DELETE_SUBMITTING = "[Offer] Delete_Submitting";
export const OFFER_DELETE_SUCCESS = "[Offer] Delete_Success";
export const OFFER_DELETE_FAILURE = "[Offer] Delete_Failure";

//Estadisticas
export const STATISTIC_GET_INITIAL = "[Statistic] Get_Initial";
export const STATISTIC_GET_SUBMITTING = "[Statistic] Get_Submitting";
export const STATISTIC_GET_FORM_SUBMITTING = "[Statistic] Get_Submitting_Form";
export const STATISTIC_CATEGORIES_GET_SUCCESS =
  "[Statistic] Categories_Get_Success";
export const STATISTIC_BUDGET_GET_SUCCESS = "[Statistic] Budget_Get_Success";
export const STATISTIC_GET_FAILURE = "[Statistic] Get_Failure";

//Alertas
export const ALERT_ADD_INITIAL = "[Alert] Add_Initial";
export const ALERT_ADD_SUBMITTING = "[Alert] Add_Submitting";
export const ALERT_ADD_SUCCESS = "[Alert] Add_Success";
export const ALERT_ADD_FAILURE = "[Alert] Add_Failure";
export const ALERT_GET_INITIAL = "[Alert] Get_Initial";
export const ALERT_GET_SUBMITTING = "[Alert] Get_Submitting";
export const ALERT_GET_SUCCESS = "[Alert] Get_Success";
export const ALERT_GET_FAILURE = "[Alert] Get_Failure";
export const ALERT_EDIT_INITIAL = "[Alert] Edit_Initial";
export const ALERT_EDIT_SUBMITTING = "[Alert] Edit_Submitting";
export const ALERT_EDIT_SUCCESS = "[Alert] Edit_Success";
export const ALERT_EDIT_FAILURE = "[Alert] Edit_Failure";
export const ALERT_DELETE_INITIAL = "[Alert] Delete_Initial";
export const ALERT_DELETE_SUBMITTING = "[Alert] Delete_Submitting";
export const ALERT_DELETE_SUCCESS = "[Alert] Delete_Success";
export const ALERT_DELETE_FAILURE = "[Alert] Delete_Failure";
export const ALERT_GET_ONE_SUCCESS = "[Alert] Get_One_Success";

//Publicidad
export const ADVERTISING_GET_SUCCESS = "[Advertising] Get_Success";
export const ADVERTISING_GET_SUBMITTING = "[Advertising] Get_Submitting";
export const ADVERTISING_GET_FAILURE = "[Advertising] Get_Failure";

//Reportes
export const REPORTE_ADD_INITIAL = "[REPORTE] Add_Initial";
export const REPORTE_ADD_SUBMITTING = "[REPORTE] Add_Submitting";
export const REPORTE_ADD_SUCCESS = "[REPORTE] Add_Success";
export const REPORTE_ADD_FAILURE = "[REPORTE] Add_Failure";

//Calificaciones\
export const CALIFICATION_GET_SUCCESS = "[Calification] Get_Success";
export const CALIFICATION_GET_SUBMITTING = "[Calification] Get_Submitting";
export const CALIFICATION_GET_FAILURE = "[Calification] Get_Failure";
export const CALIFICATION_ADD_SUCCESS = "[Calification] Add_Success";
export const CALIFICATION_ADD_SUBMITTING = "[Calification] Add_Submitting";
export const CALIFICATION_ADD_FAILURE = "[Calification] Add_Failure";
export const CALIFICATION_ID_FAILURE = "[Calification] Id_Failure";
export const CALIFICATION_ID_SUCCESS = "[Calification] Id_Success";
