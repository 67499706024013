import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 512 512"
    /* style={{
      enableBackground: "new 0 0 512 512",
    }} */
    xmlSpace="preserve"
    width={170}
    height={163}
    {...props}
  >
    <style>{".st1{fill:#007ac9}.st4{fill:#0039a6}.st5{fill:#f9f7f8}"}</style>
    <path
      d="M347.3 409.8c0 29-23.5 52.4-52.4 52.4H141.6l-57.9 48.5c-1.3 1.1-3.2.2-3.2-1.5v-42.5c0-2.5-2-4.5-4.5-4.5H52.4c-29 0-52.4-23.5-52.4-52.4V236.3c0-29 23.5-52.4 52.4-52.4h294.8l.1 225.9z"
      style={{
        fill: "#009fda",
      }}
    />
    <path
      className="st1"
      d="M72.1 409.8V236.3c0-29 23.5-52.4 52.4-52.4H52.4c-29 0-52.4 23.5-52.4 52.4v173.4c0 29 23.5 52.4 52.4 52.4H76c2.5 0 4.5 2 4.5 4.5v42.5c0 1.7 1.9 2.6 3.2 1.5l57.9-48.5h-17c-29 .1-52.5-23.4-52.5-52.3zM347.3 331.8V183.9H182.5c5.9 36.2 22.9 70 49 96.3v53.9c0 14.7 11.9 26.6 26.6 26.6 6.6 0 12.9-2.5 17.8-6.9l31.5-28.6c13 3.7 26.3 5.9 39.9 6.6z"
    />
    <path
      d="M356.3.4c-85.6.1-155.6 70.2-155.4 155.8.1 45.7 19.9 86.7 51.3 115.1V334c0 5.2 6.1 7.9 9.9 4.4l40.4-36.6c18.4 6.8 38.3 10.2 59.2 9.5C445.9 308.5 512 240 512 155.8 512 69.9 442.3.3 356.3.4z"
      style={{
        fill: "#fee97d",
      }}
    />
    <path
      d="M254.9 182.9C254.7 92.2 326.1 17.3 415.5 12 397.2 4.5 377.2.4 356.3.4c-85.6.1-155.6 70.2-155.4 155.8.1 45.7 19.9 86.7 51.3 115.1V334c0 5.2 6.1 7.9 9.9 4.4l40.4-36.6c.5.2 1 .4 1.5.5-30.3-30.7-49-72.9-49.1-119.4z"
      style={{
        fill: "#ffde55",
      }}
    />
    <path
      className="st4"
      d="M358.3 274.4c-10.3 0-18.7-8.4-18.7-18.7 0-10.3 8.4-18.7 18.7-18.7 10.3 0 18.7 8.4 18.7 18.7-.1 10.3-8.5 18.7-18.7 18.7zM358.3 210.6c-8.5 0-15.5-6.9-15.5-15.5v-31.2c0-11.4 8.5-21.1 19.9-22.4 19.5-2.3 33.6-19.6 32.1-39.2-1.4-18.1-16.7-32.9-34.9-33.6-18.9-.8-35.2 12.7-37.8 31.4-.2 1.7-.4 3.4-.4 5.1 0 8.5-6.9 15.5-15.5 15.5s-15.5-6.9-15.5-15.5c0-3.1.2-6.3.6-9.4 4.8-34.5 34.8-59.5 69.7-58 34.1 1.4 61.8 28.1 64.4 62.1 2.6 33.7-19.7 63.5-51.8 71v24.2c.1 8.6-6.8 15.5-15.3 15.5z"
    />
    <path
      className="st5"
      d="M205.6 278.7h-111c-4.3 0-7.7-3.5-7.7-7.7s3.5-7.7 7.7-7.7h111c4.3 0 7.7 3.5 7.7 7.7s-3.4 7.7-7.7 7.7zM221.1 329.9H48.6c-4.3 0-7.7-3.5-7.7-7.7s3.5-7.7 7.7-7.7h172.5c4.3 0 7.7 3.5 7.7 7.7s-3.4 7.7-7.7 7.7zM290.8 381.1H48.6c-4.3 0-7.7-3.5-7.7-7.7s3.5-7.7 7.7-7.7h242.2c4.3 0 7.7 3.5 7.7 7.7s-3.5 7.7-7.7 7.7z"
    />
  </svg>
)

export default SvgComponent
