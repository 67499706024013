import React from "react";
import FacebookIcon from "../assets/icons/FacebookIcon";
import InstagramIcon from "../assets/icons/InstagramIcon";
import TwitterIcon from "../assets/icons/TwitterIcon";
import YoutubeIcon from "../assets/icons/YoutubeIcon";
import logoEMP from "../assets/images/LogoEMP.png";
import fotoHector from "../assets/images/hector.jpg";

export const WhoWeAre = () => {
  return (
    <div className="flex flex-col pb-6">
      <div
        className="object-cover bg-imagen-quienes-somos bg-cover h-96 w-full items-center flex justify-center left-1/2"
        style={{
          height: "500px",
          backgroundPositionX: "center",
        }}
      >
        <p className="text-3xl text-center text-white font-bold">
          Ayudamos a satisfacer las necesidades <br />
          de las personas
        </p>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col mt-12 items-center mb-10">
          <div>
            <span className="mr-4 font-bold text-deepBlue text-4xl">
              DemandApp
            </span>
          </div>
          <div className="flex flex-row mt-2">
            <div className="mr-5">
              <FacebookIcon height={32} width={32} />
            </div>
            <div className="mr-5 ">
              <InstagramIcon height={32} width={32} />
            </div>
            <div className="mr-5">
              <TwitterIcon height={32} width={32} />
            </div>
            <div className="">
              <YoutubeIcon height={32} width={32} />
            </div>
          </div>
        </div>

        <p className="w-11/12 text-3xl text-center mb-7 text-gray-400 font-bold">
          Creamos los medios para que las personas puedan hacer visible sus
          necesidades y conectarse con quienes ofrecen servicios, apoyando el
          emprendimiento con la generación de nuevas oportunidades.
        </p>

        <p className="font-bold text-deepBlue pb-8 text-3xl pt-5">
          Nuestra empresa
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-1 gap-1 mx-auto pb-8 justifyContent:center">
          <div className="flex flex-col items-center max-h-119 w-full md:w-96 bg-deepBlue rounded overflow-hidden shadow-lg mr-4 ">
            <img
              className="w-48 h-48 rounded-full pt-2 "
              src={fotoHector}
              alt="Héctor Muñoz"
            />
            <p className="text-white text-center font-bold  text-xl pt-5">
              Héctor Muñoz Ortega{" "}
            </p>
            <p className="text-white text-center font-bold  text-xl ">
              Cofundador{" "}
            </p>
            <p className="text-gray-100 text-md font-bold text-center pt-5 pb-6  pl-0 md:pl-3 md:pr-3 ">
              “No solo buscamos conectar la demanda con la oferta, si no que
              también la maximización del uso de todos los recursos involucrados
              en el mercado“
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <p className="font-bold text-deepBlue pb-2 text-3xl pt-5">
            Nuestros Colaboradores
          </p>
          <p className="w-11/12 text-2xl text-center mb-7 text-gray-400 font-bold">
            Agradecemos la colaboración y desarrollo de EmpDigital
          </p>
          <div className="h-28 w-36 items-center justify-center pt-2 ">
            <img src={logoEMP} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};
