import { isAfter } from "date-fns";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import regionesComunas from "../../assets/RegionesComunas.json";
import { Button } from "../../components/Button";
import { CustomSelect } from "../../components/CustomSelect";
//import { Select } from "../../components/Select";
import { TextField } from "../../components/TextField";
import { FormState } from "../../models/form_states";
import { addAlert, formStateInitial  } from "../../redux/actions/alert.action";
import { getCategories } from "../../redux/actions/categories.action";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { AlertState } from "../../redux/reducers/alert.reducer";
import { CategoriesState } from "../../redux/reducers/categories.reducer";
import { RootState } from "../../redux/reducers/root.reducer";

export const OffererAddAlert = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCategories());
    // eslint-disable-next-line
  }, []);

  const { categories } = useSelector<RootState, CategoriesState>(
    (state) => state.categoriesReducer
  );
  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);

  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Crear Alerta" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Crear Alerta" && x.Posicion === "inferior"
  );
  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);

  const {
    add: { state: addState, error },
  } = useSelector<RootState, AlertState>((state) => state.alertReducer);


  useEffect(() => {
    if(addState === FormState.Success) {
      dispatch(formStateInitial())
      navigate("/oferente/alertas")
       /* window.location.reload() */
    }
  }, [addState])

 
  
  
  

  const { handleSubmit, values, handleChange, touched, setFieldValue, errors } =
    useFormik({
      initialValues: {
        Titulo: "",
        Region: "",
        Comuna: "",
        Categorias: "",
      },
      onSubmit: (values) => {
        dispatch(addAlert(values));
      },
      validationSchema: yup.object({
        Titulo: yup.string().required("Debe ingresar titulo"),
        Region: yup.string().required("Debe ingresar región"),
        Comuna: yup.string().required("Debe ingresar comuna"),
        Categorias: yup.array().required("Debe ingresar categoria"),
      }),
    });

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="py-10 px-8 xl:px-10 lg:container lg:mx-auto"
    >
      <Helmet title="Crear Alerta" />
      <h1 className=" text-3xl font-bold text-deepBlue mb-5 ">Crear Alerta</h1>
      {AdvertisingSup.length === 1 && isAfter(new Date(AdvertisingSup[0].FechaTermino), new Date()) && (
        <a href={AdvertisingSup[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingSup[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingSup[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}
        </a>
      )}
      <motion.div>
        <form
          className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full md:mx-5"
          onSubmit={handleSubmit}
        >
          <div className="">
            <TextField
              label="Título"
              placeholder="Título"
              id="Titulo"
              divClassName="mb-4"
              value={values.Titulo}
              onChange={handleChange}
              helperText={touched.Titulo && errors.Titulo}
              error={touched.Titulo && Boolean(errors.Titulo)}
            />

            <CustomSelect
              label="Categorías"
              id="Categorias"
              options={categories.map((categorie) => ({
                label: categorie.Nombre,
                value: categorie.Nombre,
              }))}
              isMulti
              placeholder="Selecciona 3 categorías max."
              onChange={(value) => {
                setFieldValue("Categorias", value);
              }}
              value={values.Categorias}
              isSearchable
              autoFocus
              isClearable
              isOptionDisabled={() => values.Categorias.length>=3 }
            />
          </div>
          <div>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <CustomSelect
                  id="Region"
                  label="Región"
                  placeholder="Seleccionar Región"
                  options={regionesComunas.regiones.map((region) => ({
                    label: region.region,
                    value: region.region,
                  }))}
                  isSearchable
                  onChange={(value: any) => {
                    setFieldValue("Region", value.value);
                  }}
                />
              </div>
              <div>
                <CustomSelect
                  id="Comuna"
                  label="Comuna"
                  className="mt-0"
                  placeholder="Seleccionar Comuna"
                  isSearchable
                  options={
                    values.Region
                      ? regionesComunas.regiones
                          .find((x) => x.region === values.Region)
                          ?.comunas.map((comuna) => ({
                            label: comuna,
                            value: comuna,
                          }))
                      : []
                  }
                  onChange={(value: any) => {
                    setFieldValue("Comuna", value.value);
                  }}
                />
              </div>
              <div className="justify-center">
                {
                  error == undefined ?
                  (<Button
                    type="submit"
                    text="Crear Alerta"
                    color="secondary"
                    fullWidth
                    className="mt-10"
                    isLoading={addState === FormState.Submitting}
                    disabled={addState === FormState.Submitting}
                  />): 
                  <span>Ya existen 3 alertas</span>
                }
              </div>
            </div>
          </div>
        </form>
      </motion.div>
      {AdvertisingBot.length === 1 && isAfter(new Date(AdvertisingBot[0].FechaTermino), new Date()) && (
        <a href={AdvertisingBot[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingBot[0].Imagen.URL}
              className="mx-auto rounded-md mt-5 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingBot[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}
        </a>
      )}
    </motion.main>
  );
};
