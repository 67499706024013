import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyDD7KKmOyu-PAMoV0HKpzrk_PG2gGJG1v8",
  authDomain: "demandapp-4cc44.firebaseapp.com",
  projectId: "demandapp-4cc44",
  storageBucket: "demandapp-4cc44.appspot.com",
  messagingSenderId: "114263255850",
  appId: "1:114263255850:web:09f3df1c00dcab68668c53",
  measurementId: "G-JM7XQ6ZXB0"
};

export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics();
export const firestore = getFirestore();
export const storage = getStorage();
export const auth = getAuth();
