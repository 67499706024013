import React from "react";
import { ArrayKey, Statistic } from "../../../models/Statistic";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
type DataSet = ChartDataset<"bar", number[]>;

interface Props {
  statistics: Statistic;
}
export const CategoriesDashboard: React.FC<Props> = ({ statistics }) => {
  const backgounds = [
    "rgba(255, 99, 0, 0.5)",
    "rgba(0, 99, 255, 0.5)",
    "rgba(255, 255, 0, 0.5)",
    "rgba(0, 255, 0, 0.5)",
    "rgba(255, 0, 0, 0.5)",
  ];

  const getName = (name: string) => {
    switch (name) {
      case "CategoriaMasDemandada":
        return "Categorías Más Demandadas";
      case "CategoriaMenosDemandada":
        return "Categorías Menos Demandadas";
      case "CategoriaMasOfertada":
        return "Categorías Más Ofertadas";
      case "CategoriaMenosOfertada":
        return "Categorías Menos Ofertadas";

      default:
        break;
    }
  };

  return (
    <div className="pt-5 px-10  gap-10 md:grid md:grid-cols-2">
      {Object.entries(statistics)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map((entry) => {
          if (entry[1].length === 0) {
            return (
              <div
                key={entry[0]}
                className="bg-primary-50 rounded-lg mb-5 place-items-center flex"
                style={{ height: "261px" }}
              >
                <span className="text-center mx-auto text-xl text-gray-500">
                  No hay suficiente información para generar este grafico
                </span>
              </div>
            );
          } else {
            const dataEntry: ArrayKey = entry[1];
            const labels = dataEntry.map((e) => Object.keys(e)).flat();

            const dataSets: DataSet[] = [
              {
                data: dataEntry.map((v) => {
                  const value = Object.entries(v)[0];
                  return value[1];
                }),
                backgroundColor: backgounds,
              },
            ];
            return (
              <div key={entry[0]} style={{}}>
                <Bar
                  options={{
                    responsive: true,
                    scales: {
                      y: {
                        ticks: {
                          precision: 0,
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                        position: "top" as const,
                      },
                      title: {
                        display: true,
                        text: getName(entry[0]),
                        font: {
                          size: 18,
                        },
                      },
                    },
                  }}
                  data={{
                    labels: labels,
                    datasets: dataSets,
                  }}
                />
              </div>
            );
          }
        })}
    </div>
  );
};
