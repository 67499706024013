import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginCard } from "../../../components/LoginCard";
import { FormState } from "../../../models/form_state";
import { PlaintiffOffer } from "../../../models/PlaintiffOffer";
import {
  AcceptOffer,
  PlaintiffRequest,
} from "../../../models/PlaintiffRequest";
import { AuthState } from "../../../redux/reducers/auth.reducer";
import { OfferState } from "../../../redux/reducers/offer.reducer";
import { RootState } from "../../../redux/reducers/root.reducer";
import { OffererAcceptCard } from "./OffererAcceptCard";
import { OfferLoadingCard } from "./OfferLoadingCard";
import { MakeYourOfferForm } from "./MakeYourOfferForm";
import { RequestsState } from "../../../redux/reducers/requests.reducer";
import { isOffered } from "../../../redux/actions/requests.actions";
import { useNavigate } from "react-router-dom";
import { addCalificationOfferer } from "../../../redux/actions/calification.action";
import { Button } from "../../../components/Button";
import StarRatingComponent from "react-star-rating-component";
import { Modal } from "../../../components/Modal";
interface Props {
  selectedRequest?: PlaintiffRequest;
  selectedOffer?: PlaintiffOffer;
}

export const MakeYourOfferSection: React.FC<Props> = ({
  selectedRequest,
  selectedOffer,
}) => {
  const { state: authState } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );

  const { edit: offerState } = useSelector<RootState, OfferState>(
    (state) => state.offerReducer
  );

  const { searchOffer } = useSelector<RootState, RequestsState>(
    (state) => state.requestReducer
  );

  const [selected, setSelected] = useState<any>();
  const [calificationModal, setCalificationModal] = useState(false);
  const [calification, setCalification] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRate = (userRequest: any) => {
    setCalificationModal(true);
    setSelected(userRequest);
  };

  const handleOnClose = () => {
    setCalificationModal(false);
  };

  const handleRateUser = (calification: number, offer: any, requestId: any) => {
    //dispatch(addCalification(calification, offer, requestId));
    setCalificationModal(false);
    navigate("/demandante/solicitudesRealizadas");
  };
  function onStarClick(nextValue: number, prevValue: number, name: string) {
    setCalification(nextValue);
  }

  useEffect(() => {
    dispatch(isOffered(selectedRequest!.id));
    // eslint-disable-next-line
  }, [authState]);

  const RenderContent = () => {
    if (authState !== FormState.Success) {
      return <LoginCard requestId={selectedRequest!.id} />;
    } else if (offerState.state === FormState.Submitting) {
      return <OfferLoadingCard />;
    } else if (
      searchOffer.searchedOffer &&
      searchOffer.state === FormState.Success &&
      selectedRequest && searchOffer.searchedOffer.Eliminada === false
    ) {
      return (
        <OffererAcceptCard
          handleRate={handleRate}
          userRequest={selectedRequest?.Usuario as any}
          isRated={selectedRequest.Calificado}
          offer={searchOffer.searchedOffer}
          isOfferAcepted={
            (selectedRequest?.OfertaAceptada as AcceptOffer)?.OfertaId ===
              selectedOffer?.id && selectedRequest?.OfertaAceptada !== null
          }
        />
      );
    } else if (
      offerState.state === FormState.Success &&
      selectedRequest &&
      selectedOffer && selectedOffer.Eliminada === false
    ) {
      return (
        <OffererAcceptCard
          handleRate={handleRate}
          userRequest={selectedRequest.Usuario as any}
          isRated={selectedRequest.Calificado}
          offer={selectedOffer as PlaintiffOffer}
          isOfferAcepted={
            (selectedRequest?.OfertaAceptada as AcceptOffer)?.OfertaId ===
              selectedOffer.id && selectedRequest?.OfertaAceptada !== null
          }
        />
      );
    } else if (selectedRequest && searchOffer.searchedOffer === null) {
      return <MakeYourOfferForm requestId={selectedRequest.id} requestCat={selectedRequest.Categoria}/>;
    } else {
      return (
        <div>
          <React.Fragment>
            {new Array(3).fill(0).map((_, index) => (
              <OfferLoadingCard key={`loading-offers-${index}`} />
            ))}
          </React.Fragment>
        </div>
      );
    }
  };

  return (
    <div className="w-full">
      <RenderContent />
      {/* MODAL PARA CALIFICAR USUARIO */}
      <div>
        <Modal
          isVisible={calificationModal}
          onClose={handleOnClose}
          isDismiseble
        >
          <div className="bg-white rounded-lg h-full shadow-lg p-5 content-between">
            <h1 className="text-lg text-deepBlue font-bold">
              {`Califica tu experiencia con ${selectedRequest?.Usuario.Nombre} ${selectedRequest?.Usuario?.Apellido}`}
            </h1>

            <div className="flex place-content-center" style={{ fontSize: 60 }}>
              <StarRatingComponent
                name="Calificar Usuario"
                starCount={5}
                starColor={"#009fda"}
                emptyStarColor={"#B6B6B6"}
                value={calification}
                editing
                onStarClick={onStarClick}
                onStarHover={onStarClick}
              />
            </div>
            <div className="flex place-content-center mt-2">
              <Button
                text="Calificar"
                className="text-sm bg-primary-light"
                onClick={() =>
                  handleRateUser(
                    calification,
                    selected,
                    selectedRequest?.Usuario.Id
                  )
                }
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
