import { format, isAfter } from "date-fns";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { PlaintiffOffer } from "../../../models/PlaintiffOffer";
import { PlaintiffRequest } from "../../../models/PlaintiffRequest";
import { addCalificationOfferer } from "../../../redux/actions/calification.action";
import { MyOfferCard } from "./MyOfferCard";

interface Props {
  userRequest: {
    Id: string;
    Nombre: string;
    Apellido: string;
    Direccion: string;
    Telefono: string;
    Email: string;
  };
  offer: PlaintiffOffer;
  isOfferAcepted: boolean;
  handleRate: (offer: any) => void;
  isRated: boolean;
}

export const OffererAcceptCard: React.FC<Props> = ({
  userRequest,
  offer,
  isOfferAcepted,
  handleRate,
  isRated,
}) => {
  //CONTROL MODAL CALIFICAR USUARIO

  return (
    <div>
      {isOfferAcepted ? (
        <div className="mt-12">
          <h1 className="text-2xl text-deepBlue font-bold">Oferta Aceptada</h1>
          <div className="bg-grayContainer rounded-xl p-5 m-2 flex flex-col font-semibold relative">
            <span className="text-lg text-blue-800 font-bold mb-2">
              Datos de contacto solicitante:
            </span>
            <span className="textm-sm text-secondary-light">
              Nombre:{" "}
              <span className="text-gray-400">{`${userRequest?.Nombre} ${userRequest?.Apellido}`}</span>
            </span>
            <span className="textm-sm text-secondary-light">
              Dirección:{" "}
              <span className="text-gray-400">{userRequest?.Direccion}</span>
            </span>
            <span className="textm-sm text-secondary-light">
              Email: <span className="text-gray-400">{userRequest?.Email}</span>
            </span>
            <span className="textm-sm text-secondary-light">
              Teléfono:{"+56 "}
              <span className="text-gray-400">{userRequest?.Telefono}</span>
            </span>
          </div>

          <div className="bg-greenOffer rounded-xl p-5 m-2 relative flex flex-col">
            <span className="text-lg text-blue-800 font-bold mb-2">
              Mi oferta:
            </span>
            <span className="textm-lg text-secondary-light font-semibold">
              Presupuesto:{" "}
              <span className="text-gray-400">
                {offer?.Precio?.toLocaleString("es")}
              </span>
            </span>
            <span className="textm-sm text-secondary-light font-semibold">
              Fecha inicio:{" "}
              <span className="text-gray-400">{`${format(
                new Date(offer?.FechaInicio),
                "dd/MM/yyyy"
              )}`}</span>
            </span>
            <span className="textm-sm text-secondary-light font-semibold">
              Fecha termino:{" "}
              <span className="text-gray-400">{`${format(
                new Date(offer?.FechaTermino),
                "dd/MM/yyyy"
              )}`}</span>
            </span>
            <span className="textm-sm text-secondary-light font-semibold">
              Cantidad:{" "}
              <span className="text-gray-400">{`${offer?.Cantidad?.Cantidad} ${offer?.Cantidad.Tipo}`}</span>
            </span>
          </div>

          <div className="bg-grayContainer rounded-xl p-5 m-2 flex flex-col font-semibold relative">
            <span className="text-lg text-blue-800 font-bold mb-2">
              Mensaje:
            </span>
            <span className="text-gray-400">{offer?.Mensaje}</span>
            <div className="flex place-content-center mt-5">
              {isAfter(new Date(), new Date(offer?.FechaTermino)) &&
                isRated === false && (
                  <Button
                    text="Calificar usuario"
                    className="bg-primary"
                    onClick={() => handleRate(userRequest)}
                  />
                )}
            </div>
          </div>
        </div>
      ) : (
        <MyOfferCard offer={offer} />
      )}
    </div>
  );
};
