import React from "react";

export const LoadingCard = () => {
  return (
    <div className="rounded-xl p-8 mt-5 bg-gray-100">
      <div className="animate-pulse flex">
        <div className="flex-1 space-y-6">
          <div className="h-2 bg-gray-300 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray-300 rounded col-span-2"></div>
            </div>
            <div className="h-2 bg-gray-300 rounded"></div>
          </div>
          <div className="flex justify-center items-center">
            <div className="h-12 w-1/4 rounded-full bg-gray-300"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
