import * as React from "react"
import { SVGProps } from "react"

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={70.69}
    height={70.68}
    viewBox="0 0 70.69 70.68"
    {...props}
  >
    <circle
      data-name="Elipse 9 copia 10"
      cx={35.345}
      cy={35.34}
      r={35.345}
      style={{
        fill: "#5aa1ec",
      }}
    />
    <image
      data-name="Objeto inteligente vectorial"
      x={22}
      y={24.84}
      width={30}
      height={25}
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAZCAYAAAAmNZ4aAAACDElEQVRIibWWz4tOYRTHP16jEBYUI2XCQrISxoaV0NjMxsqMIjsL2VjjD5AaKbbI/AOUpJRZGL9KyjRKaSwIG6+XSX7MRw/PU7frvu/c+3rvt57uPc+P8z2n5zznHNRejHXqmDqjzqlf1fvqqLogp3+XujwJm9RTXRowpH62PW6rm9Vj6j11IpxLh8/FY6MVSbepsx1I82iqW9VGH38xGL/XgJXAGOVwAVhScu834BJwFOhLlk/kLLse7mEebzdU8DSLZ0F3I1rzPmfdCPASOAI0CqwP2FnS0yyeAPuAVlJ6t2DTWuBqNOAksCa3vqwi6SywB/gYhD+hHpW8KlCexRzwFHgITAMDwOkKxC1gRRIScQio7cAtYFFFT8oiXGd/2pui+ibQjN7srol4Jisk4ingeE2ECVNZIQXXeM2kAZNZId0x8X4P1kgcgvFNErJv9DDwuCbSySxpnrgZ39lZ4EOPia/kJ/JZ6QSwH/jSQ9IQzTf+mc3l34GK1aYMRopyfVHyD6XxV49I77QrMu0qz7D69j9J36n9VYnDWKweUse7IA0Ff7BTWW1X8gJ+AquAoYrBFF7HMPCo466cJQujpWfU1114Oq1uKdM2EaPugfoidofd4Lt6Xl1atl9LP+vVi/N0i0VoqZdjl1qpO83m6oDQuB0A9gI7gI3A6rj2A/gUm4DnsWsJo3qyAX4DZtq9jP8R3vsAAAAASUVORK5CYII="
    />
  </svg>
)

export default TwitterIcon
