import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";

// For routes that can only be accessed by "Demandante" type authenticated users
export const AuthGuard = ({ children }: any) => {
  const auth = useSelector<RootState, AuthState>((state) => state.authReducer);
  const location = useLocation();

  localStorage.setItem("lastPath", location.pathname);

  if (!auth?.user || auth?.user?.TipoUsuario !== "Demandante") {
    return <Navigate to="/solicitudesActivas" />;
  }

  return children;
};