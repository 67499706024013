import * as React from "react";

function UserOutlinedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" {...props}>
      <path
        d="M42.04 32.6a22.63 22.63 0 00-8.62-5.41c3.44-2.37 5.69-6.33 5.69-10.81 0-7.23-5.88-13.11-13.11-13.11S12.89 9.15 12.89 16.38c0 4.48 2.26 8.44 5.69 10.81a22.63 22.63 0 00-8.62 5.41c-4.28 4.28-6.64 9.98-6.64 16.04h3.54C6.86 38.08 15.45 29.5 26 29.5s19.14 8.59 19.14 19.14h3.54c.01-6.06-2.35-11.76-6.64-16.04zM26 25.95c-5.28 0-9.57-4.29-9.57-9.57S20.72 6.81 26 6.81s9.57 4.29 9.57 9.57-4.29 9.57-9.57 9.57z"
        fill="#0079c8"
      />
    </svg>
  );
}

export default UserOutlinedIcon;
