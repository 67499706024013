import { useFormik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../redux/actions/categories.action";
import {
    getAllRequestsOrder,
    getRequestFilteredOfferer
} from "../redux/actions/requests.actions";
import { CategoriesState } from "../redux/reducers/categories.reducer";
import { RequestsState } from "../redux/reducers/requests.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { MenuItem } from "./MenuItem";
interface Props {
  isVisible: boolean;
  onClose: VoidFunction;
  title?: string;
}

interface MenuItem {
  label: string;
  action: VoidFunction;
}


export const OrderByMenu: React.FC<Props> = ({ onClose, isVisible }) => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref?.current?.contains(event.target)) {
      setTimeout(() => {
        onClose();
      }, 50);
    }
  };

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        label: "Más Recientes",
        action: () => dispatch(getAllRequestsOrder({orderBy: "FechaCreacion", order: "desc"})),
      },
      {
        label: "Más Antiguas",
        action: () => dispatch(getAllRequestsOrder({orderBy: "FechaCreacion", order: "asc"}))
      },
      {
        label: "Precio menor a mayor",
        action: () => dispatch(getAllRequestsOrder({orderBy: "Precio", order: "asc"}))
      },
      {
        label: "Precio mayor a menor",
        action: () => dispatch(getAllRequestsOrder({orderBy: "Precio", order: "desc"}))
      },
    ],
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AnimatePresence
      initial={false}
      exitBeforeEnter={true}
      onExitComplete={() => null}
    >
      {isVisible && (
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="bg-primary pt-5 pb-10 px-6 flex flex-col items-center z-30 absolute right-0 top-12"
          style={{
            width: 300,
            marginLeft: 0,
            marginTop: 15,
            maxWidth: "100vw",
            height: 230,
          }}
        >
          <h4 className="text-lg font-bold text-white text-center">
            Ordenar Por:
          </h4>
          <ul className="list-none w-full">
            {menuItems.map((item, index) => (
              <li
                key={`plaintiff-menu-${index}`}
                className="h-10 flex pointer hover:bg-gray-500 hover:bg-opacity-20 w-full px-4 items-center cursor-pointer"
                onClick={() => {
                  item.action();
                  onClose();
                }}
              >
                <span className="text-sm text-white font-semibold ml-5">
                  {item.label}
                </span>
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
