import {
  collection, getDocs, limit, orderBy, query, where
} from "@firebase/firestore";
import { firestore } from "../../configs/firebase";
import { AppThunk } from "../../models/app-thunk";
import * as types from "../types";

export const getCategories = (): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.CATEGORIES_SUBMITTING,
    });
    try {
      const catRef = collection(firestore, "Categorias");
      const q = query(
        catRef,
        where("Bloqueado", "==", false),
        orderBy("FechaCreacion", "desc")
      );
      const response = await getDocs(q);
      const categories = response.docs.map((x) => ({ ...x.data(), id: x.id }));
      dispatch({
        type: types.CATEGORIES_SUCCESS,
        payload: categories,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.CATEGORIES_FAILURE,
        payload: "Error inesperado",
      });
    }
  };
};

export const getCountRequestsCategories = (): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.CATEGORIES_COUNT_REQUEST_SUBMITTING,
    });
    try {
      const catRef = collection(firestore, "Categorias");
      const q = query(
        catRef,
        where("Bloqueado", "==", false),
        orderBy("CantidadSolicitudes", "desc"),
        limit(5)
      );
      const response = await getDocs(q);
      const categories = response.docs.map((x) => ({ ...x.data(), id: x.id }));
      dispatch({
        type: types.CATEGORIES_COUNT_REQUEST_SUCCESS,
        payload: categories,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.CATEGORIES_COUNT_REQUEST_FAILURE,
        payload: "Error inesperado",
      });
    }
  };
};
